<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>FMB120</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">
        <div class="page-title-content ptb-50">
            <h2>FMB120</h2>
        </div>
        <p>Le FMB120 est une balise compacte pour un usage professionnel avec antennes internes GSM et GNSS.
            Ces nombres sorties sorties et entrées digitales et analogiques permettent la connexion de nombreux
            accessoires comme l’identifiant chauffeur, les Can-bus, les sondes de fuel,...)</p>
        <div class="row ">
            <div class="col-lg-6 col-md-12">
                <img src="assets/img/solutions/FMB120.png" alt="image" >
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="services-details-content">
                    <!--<h2>Module de tracking et de géolocalisation</h2>-->
                    <h5>Caractéristiques physiques</h5>
                    <ul>
                        <li>Dimensions : 65 (L) x 56 (l) x 18,9 (h) mm</li>
                        <li>Poids:55g</li>
                        <li>IP41</li>
                    </ul>

                    <h5>Caractérisitiques électriques</h5>
                    <ul>
                        <li>Voltage:10V–30V</li>
                        <li>Batterie : 170 mAh Li-Ion battery 3.7 V</li>
                    </ul>

                    <h5>Autres caractéristiques</h5>

                    <ul>
                        <li>Digital Input: 3 / Digital Output: 2</li>
                        <li>Analog Input: 2 / 1-Wire: 1</li>
                    </ul>
                </div>
            </div>

        </div>




        <div class="services-area pt-70 pb-70 bg-F9FAFB">
            <h4 class="text-center">Fonctionnalités et options du FMB120 :</h4>
            <div class="row mt-5">
                <div class="col-lg-6 col-md-12">
                    <div class="services-details-content">
                        <ul>
                            <li> Carte Sim Internationale intégrée fonctionnant en roaming
                                sur tout le continent africain sans frais supplémentaire </li>
                            <li>
                                Coupure moteur à distance
                                <div>
                                    <img src="assets/img/solutions/moteur-distance.png" alt="image" >
                                </div>

                            </li>
                            <li>
                                Buzzer ( préviens des excès de vitesse)
                                <div>
                                    <img src="assets/img/solutions/buzzer.png" alt="image" >
                                </div>

                            </li>
                            <li>
                                Badging et identifiant chauffeur
                                <div>
                                    <img src="assets/img/solutions/bagging.png" alt="image" >
                                </div>

                            </li>
                            <li>
                                Bouton panique
                                <div>
                                    <img src="assets/img/solutions/button-panique.png" alt="image">
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Les autres solutions</h2>
        </div>
        <div class="row justify-content-center">
            <app-featured-services></app-featured-services>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->