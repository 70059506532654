import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-eco-drive',
  templateUrl: './eco-drive.component.html',
  styleUrls: ['./eco-drive.component.scss']
})
export class EcoDriveComponent implements OnInit{


  constructor() {
  }

  ngOnInit(): void {
  }

}
