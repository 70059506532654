<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Frequently Asked Questions</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start FAQ Area -->
<div class="faq-area ptb-100">
    <div class="container">
        <nav d>
            <div class="faq-tabs nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-about-naxai-tab" data-bs-toggle="tab" data-bs-target="#nav-about-naxai" type="button" role="tab" aria-controls="nav-about-naxai" aria-selected="true">About Naxai</button>
                <button class="nav-link" id="nav-about-security-tab" data-bs-toggle="tab" data-bs-target="#nav-about-security" type="button" role="tab" aria-controls="nav-about-security" aria-selected="false">About Security</button>
                <button class="nav-link" id="nav-children-safety-tab" data-bs-toggle="tab" data-bs-target="#nav-children-safety" type="button" role="tab" aria-controls="nav-children-safety" aria-selected="false">Parents Guide to Online Safety for Children</button>
            </div>
        </nav>
        <div class="tab-content faq-tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-about-naxai" role="tabpanel">
                <div class="faq-accordion accordion" id="faqAccordion">
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Is Naxai Hiring?
                            </button>
                        </div>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                <ul>
                                    <li>a console</li>
                                    <li>Two Joy-Con controllers that are detachable</li>
                                    <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                    <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                    <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                What geographic location does Naxai serve?
                            </button>
                        </div>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                <ul>
                                    <li>a console</li>
                                    <li>Two Joy-Con controllers that are detachable</li>
                                    <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                    <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                    <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Why should I use Naxai?
                            </button>
                        </div>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor.</p>
                                <p>Tunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                What types of applications can be tested?
                            </button>
                        </div>
                        <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <ul>
                                    <li>a console</li>
                                    <li>Two Joy-Con controllers that are detachable</li>
                                    <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                    <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                    <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                What is a Naxai Phishing Campaign?
                            </button>
                        </div>
                        <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="nav-about-security" role="tabpanel">
                <div class="faq-accordion accordion" id="faqAccordion2">
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                Is Naxai Hiring?
                            </button>
                        </div>
                        <div id="collapseSix" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                <ul>
                                    <li>a console</li>
                                    <li>Two Joy-Con controllers that are detachable</li>
                                    <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                    <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                    <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                What geographic location does Naxai serve?
                            </button>
                        </div>
                        <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                <ul>
                                    <li>a console</li>
                                    <li>Two Joy-Con controllers that are detachable</li>
                                    <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                    <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                    <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                Why should I use Naxai?
                            </button>
                        </div>
                        <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor.</p>
                                <p>Tunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                What types of applications can be tested?
                            </button>
                        </div>
                        <div id="collapseNine" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                            <div class="accordion-body">
                                <ul>
                                    <li>a console</li>
                                    <li>Two Joy-Con controllers that are detachable</li>
                                    <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                    <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                    <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                What is a Naxai Phishing Campaign?
                            </button>
                        </div>
                        <div id="collapseTen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion2">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="nav-children-safety" role="tabpanel">
                <div class="faq-accordion accordion" id="faqAccordion3">
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                                Is Naxai Hiring?
                            </button>
                        </div>
                        <div id="collapseEleven" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion3">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                <ul>
                                    <li>a console</li>
                                    <li>Two Joy-Con controllers that are detachable</li>
                                    <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                    <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                    <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                What geographic location does Naxai serve?
                            </button>
                        </div>
                        <div id="collapseTwelve" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                                <ul>
                                    <li>a console</li>
                                    <li>Two Joy-Con controllers that are detachable</li>
                                    <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                    <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                    <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                Why should I use Naxai?
                            </button>
                        </div>
                        <div id="collapseThirteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor.</p>
                                <p>Tunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                What types of applications can be tested?
                            </button>
                        </div>
                        <div id="collapseFourteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                            <div class="accordion-body">
                                <ul>
                                    <li>a console</li>
                                    <li>Two Joy-Con controllers that are detachable</li>
                                    <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                                    <li>Two straps for turning the Joy-Cons into individual controllers</li>
                                    <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <div class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                What is a Naxai Phishing Campaign?
                            </button>
                        </div>
                        <div id="collapseFifteen" class="accordion-collapse collapse" data-bs-parent="#faqAccordion3">
                            <div class="accordion-body">
                                <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End FAQ Area -->

<app-lets-talk></app-lets-talk>