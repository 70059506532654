import {Component} from '@angular/core';

@Component({
  selector: 'app-fmb120',
  templateUrl: './fmb120.component.html',
  styleUrls: ['./fmb120.component.scss']
})
export class Fmb120Component {

}
