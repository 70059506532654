import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-balises-etanches',
  templateUrl: './balises-etanches.component.html',
  styleUrls: ['./balises-etanches.component.scss']
})
export class BalisesEtanchesComponent {

  param : string;

  constructor(private route : ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.param = params['id'];
    });
  }

  ngOnInit(): void {

  }
}
