<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Maintenance</h1>
        </div>
    </div>
</div>

<div class="services-details-area ptb-70">

    <div class="container">

        <div class="text-center ptb-50">
            <h2>Module Maintenance</h2>
        </div>

        <div class="row ">

            <div class="col-lg-6 col-md-12">

                <div class="services-details-content">
                    <ul>

                        <li>
                            Automatiser les alertes de maintenance sous différentes formes :
                            <ul>
                                <li>Par la date ( contrôle technique, assurances,...)</li>
                                <li>Par le nombre de km ( Vidange d’huile, changement des pneus,...)</li>
                                <li>Heures machines ( maintenance groupe électrogène, changement pièce grue,...)</li>
                            </ul>

                        </li>
                        <li>Réduire le nombre d’accidents</li>
                        <li>Planifier chaque opération d’entretien pour chaque véhicule et garantir le suivi</li>
                        <li>Avertir automatiquement le responsable de l’entretien du parc automobile</li>
                        <li>Délivrer un outil simple et puissant pour le suivi du parc</li>
                        <li>Gain de temps et de personnels</li>
                        <li>Protection des véhicules contre les usures normales</li>
                        <li>Protection du personnel et des chauffeurs</li>
                        <li>Eviter les problèmes avec la police lors des contrôles de documents</li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/expertise/maintenance.PNG" alt="image" style="width: 100%">
            </div>


        </div>

    </div>

</div>



<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Les autres services</h2>
        </div>
        <app-tab-domaine-expertise></app-tab-domaine-expertise>
    </div>
</div>

<app-partner></app-partner>
