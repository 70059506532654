import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit{

  constructor() {
  }

  ngOnInit(): void {
  }

}
