<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h1>Nos références </h1>
        </div>
        <div class="partner-slides">
            <h2> Nos principales références en Europe</h2>
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide *ngFor="let image of europePartners;">
                    <div class="partner-item">
                        <a href="#" class="d-inline-block" target="_blank">
                            <img [src]="'assets/img/partner/europe/' + image" alt="{{image}}">
                        </a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>

        <div class="partner-slides">
            <h2>Nos principales références en Afrique</h2>
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide *ngFor="let image of afriquePartners;">
                    <div class="partner-item">
                        <a href="#" class="d-inline-block" target="_blank">
                            <img [src]="'assets/img/partner/afrique/' + image" alt="{{image}}">
                        </a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>

    </div>
</div>

