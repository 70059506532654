<!-- Start Why Choose Us Area -->
<div class="why-choose-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="../../../../assets/img/team/pdg.jpeg" alt="image">
                </div>

                <div class="why-choose-us-content text-center">
                    <h2>Mamy DIABY</h2>
                    <span class="sub-title" >PDG de HA2M TECHNOLOGY </span>
                </div>


            </div>

            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <h1 style="margin-bottom: 30px;">Pourquoi nous choisir ? </h1>

                    <p class="features-list">
                        Nous sommes convaincus que l'accéleration des innovations technologiques apporte d'immenses opportunités
                        mais aussi de nombreux défis.
                        <br>
                        c'est avec cette conviction que nous developpons, commercialisons et intégrons des solutions technologiques innovantes
                        qui visent à augmenter la productivité et la sécurité des ressources mobiles des entreprises.
                        <br>
                        Avec nos solutions, dans un monde hyper-compétitif, nous accompagnons nos clients dans leur volonté de modernisation de leur métier,
                        en alliant audace, agilité et accessibilité.
                    </p>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- End Why Choose Us Area -->