import {Component} from '@angular/core';

@Component({
  selector: 'app-hardwar-fuel',
  templateUrl: './hardwar-fuel.component.html',
  styleUrls: ['./hardwar-fuel.component.scss']
})
export class HardwarFuelComponent {

}
