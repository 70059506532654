<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1 *ngFor="let Title of pageTitle;">{{Title.pageTitle}}</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Gallery Area -->
<div class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Image of galleryItem;">
                <div class="single-gallery-item">
                    <a class="popup-btn" href="{{Image.img}}">
                        <img [src]="Image.img" alt="image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Gallery Area -->

<app-lets-talk></app-lets-talk>