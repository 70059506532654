import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-home-features',
    templateUrl: './home-features.component.html',
    styleUrls: ['./home-features.component.scss']
})
export class HomeFeaturesComponent implements OnInit {

    constructor() { }

    ttt = '200.13';

    ngOnInit(): void {

    }

    singleFeaturesBox = [
        {
            img : 'others/securysat2.png',
            title: `SECURYSAT`,
            paragraph: `L’intégralité de la solution (matériel + logiciel) est éprouvée avec plus de 25.000 véhicules 
            actuellement gérés par plus de 200 clients en Europe et en Afrique.`,
            link: `/domaine-expertise`,
            linkText: `EN SAVOIR PLUS`
        },
        {
            img : 'expertise/eco-drive.png',
            title: `ÉCO-DRIVE`,
            paragraph: `Analyser les violations réalisées par les chauffeurs ( excès de vitesses, accidents, 
            freinages et tournants brusques, trous...) et Sensibiliser les chauffeurs sur leur conduite.`,
            link: `/domaine-expertise/eco-drive`,
            linkText: `EN SAVOIR PLUS`
        },
        {
            //icon: `flaticon-cyber-security`,
            img: 'others/carburant.png',
            title: `GESTION DE CARBURANT`,
            paragraph: `Comparez automatiquement vos dépenses de carburant par rapport au kilométrage parcouru par vos véhicules.`,
            link: `/domaine-expertise/gestion-carburant`,
            linkText: `EN SAVOIR PLUS`
        },
        {
            icon: `flaticon-hacker`,
            title: `Asset Management`,
            paragraph: `Suivi et gestion du parc d’objets itinérants. Savoir en temps réel qui a déposé quoi, où et quand ? 
            Combien de temps l’objet est-il resté sur un chantier ?`,
            link: `/domaine-expertise/others-services`,
            linkText: `EN SAVOIR PLUS`
        }
    ]

}