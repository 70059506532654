<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Balises étanches</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">

        <div class="ptb-10">

            <!------------------------Debut FMB204 – 2G------------------------------->
            <div *ngIf="param==='FMB204-2G'">

                <div class="section-title">
                    <h1>FMB204 – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise FMB204 est un tracker étanche (IP67) avec connectivité Bluetooth,
                    antennes internes GNSS et GSM à gain élevé et batterie de secours haute
                    capacité intégrée.
                </p>
                <p>
                    L’appareil est conçu pour fonctionner plus longtemps sans alimentation.
                    Le FMB204 avec batterie LiPo peut fonctionner jusqu’à 6 jours en mode
                    économie d’énergie.
                </p>

                <p>
                    Le FMB204 est parfaitement adapté à l’agriculture, à la livraison, au
                    transport réfrigéré, au suivi des remorques, aux services de sécurité et
                    d’urgence et bien plus encore.
                </p>

                <p>
                    L’alimentation 6-30V rend le FMB204 adapté aux motos et au transport par
                    eau.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>
                <ul class="ml-5">
                    <li>Suivi et sécurité des véhicules de loisirs par gps.</li>
                    <li>Localisation des bateaux de pêche avec des dispositifs GPS de classe IP67.</li>
                    <li>Suivi des outils de construction avec des tags BLE.</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/FMB204_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">

                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>72,5 x 73 x 27,3 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>205g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-40°C à +85°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP67</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------FIN FMB204 – 2G------------------------------->


        </div>






    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h1>Les autres solutions</h1>
        </div>
        <div class="row justify-content-center">
            <app-other-solutions [param]="param"></app-other-solutions>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->
