<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Contactez-nous</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Addresse</h3>
                            <p>
                                <a href="#" target="_blank">
                                    Sis à Ratoma Centre en face de l'école Tom Pouce.
                                    <br>
                                    Commune de Ratoma, Conakry, République de Guinée
                                </a>
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Téléphone</h3>
                            <p><a href="tel:+44587154756">Phone: +224 623834552</a></p>
                            <p></p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class='bx bx-envelope-open'></i>
                            </div>
                            <h3>Email</h3>
                            <p><a href="mailto: contact@ha2mtechnology.com"> contact@ha2mtechnology.com</a></p>
                            <p><a href="mailto:diaby.m@ha2mtechnology.com">diaby.m@ha2mtechnology.com</a></p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info-box">
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Heures d'ouverture</h3>
                            <p>Lundi - Vendredi</p>
                            <p>8h30-12h / 13h-18h</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="contactForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group input-group is-invalid">
                                    <input required minlength="3" maxlength="100" name="name" type="text"
                                           class="form-control" id="name" formControlName="name"
                                           [ngClass]="{'required' : submitted && f.name.errors}"
                                           placeholder="Nom et prénom">
                                    <div  class="invalid-feedback" *ngIf="submitted">
                                        <div  *ngIf="f.name.hasError('required')">Le nom est obligatoire.</div>
                                        <div  *ngIf="f.name.hasError('minlength')">Le nom est minimum {{ contactForm.controls.name.errors.minlength.requiredLength }} caractère.</div>
                                        <div  *ngIf="f.name.hasError('maxlength')">Le nom comporte 50 caractères maximum.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group input-group">
                                    <input name="email" type="text" [ngClass]="{'required': submitted && f.email.errors}"
                                           class="form-control" id="email" formControlName="email"
                                           placeholder="Adresse e-mail" required>


                                    <div class="invalid-feedback" *ngIf="submitted">
                                        <div *ngIf="f.email.hasError('required')">l'adresse e-mail est obigatoire.</div>
                                        <div *ngIf="contactForm.controls.email.invalid && contactForm.get('email').value">L'adresse email est invalide.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group">
                                    <input name="number" type="text" formControlName="telephone" class="form-control" id="number"
                                           placeholder="Téléphone">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="form-group input-group">
                                    <input required name="subject" type="text" formControlName="subject" class="form-control"
                                           id="subject" placeholder="Objet" [ngClass]="{'required' : submitted && f.subject.errors}">

                                    <div class="invalid-feedback" *ngIf="submitted && f.subject.hasError('required')">
                                        <span >Le sujet est obligatoire.</span>
                                    </div>

                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group input-group">
                                    <textarea required name="message" id="message" cols="30" rows="6" class="form-control" formControlName="message"
                                              placeholder="Message..." [ngClass]="{'required' : submitted && f.message.errors}"></textarea>
                                    <div class="invalid-feedback" *ngIf="submitted && f.message.hasError('required')">Le message est obligatoire.</div>
                                </div>
                            </div>

                            <!--<div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="checkme">
                                    <label class="form-check-label" for="checkme">
                                        Accepter <a routerLink="/terms-conditions">Conditions de services</a> and <a routerLink="/privacy-policy">politique de confidentialité</a>
                                    </label>
                                </div>
                            </div>-->

                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <button type="submit" class="default-btn" >ENVOYER</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>


<div id="maps" class="pb-100">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3934.172022232375!2d-13.661175125353553!3d9.58043583011437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf1cd68b6d69106b%3A0x9637fcdc42a13699!2sTAOUYAH!5e0!3m2!1sen!2sfr!4v1682290464934!5m2!1sen!2sfr" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46660.669043361966!2d-76.17429939609431!3d43.03529129888566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d9f3b8019f2991%3A0x58d5929e21a62e5!2sDinosaur%20Bar-B-Que!5e0!3m2!1sen!2sbd!4v1593527523138!5m2!1sen!2sbd"></iframe>-->
</div>


<!-- End Contact Area -->

<!--<app-subscribe></app-subscribe>-->