<!-- Start Testimonials Area -->
<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="testimonials-image" *ngFor="let Image of testimonialsImage;">
                    <img [src]="Image.mainImg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonials-list">
                    <div *ngFor="let Title of sectionTitle;">
                        <span class="sub-title">{{Title.subTitle}}</span>
                        <h2>{{Title.title}}</h2>
                        <p>{{Title.paragraph}}</p>
                    </div>
                    <div class="testimonials-slides">
                        <owl-carousel-o [options]="testimonialsSlides">
                            <ng-template carouselSlide *ngFor="let Content of singleTestimonialsItem;">
                                <div class="single-testimonials-item">
                                    <div class="desc">
                                        <p>{{Content.feedbackText}}</p>
                                    </div>
                                    <div class="client-info">
                                        <div class="d-flex align-items-center">
                                            <img [src]="Content.userImg" alt="image">
                                            <div class="title">
                                                <h3>{{Content.userName}}</h3>
                                                <span>{{Content.userDesignation}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Testimonials Area -->