<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Balises avancées</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">

        <div class="ptb-10">

            <!------------------------Debut FMB640-2G------------------------------->
            <div *ngIf="param==='FMB640-2G'">

                <div class="section-title">
                    <h1>FMB640 – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise FMB640 est un terminal GNSS, GSM pour les applications
                    professionnelles.
                </p>
                <p>
                    Le FMB640 est conçu pour les solutions complexes, où un seul appareil peut
                    effectuer plusieurs tâches. Les caractéristiques du FMB640, telles que les
                    données CAN FMS (J1939), les <strong>données CAN carburant</strong> (J1708), les <strong>données
                    en direct du tachygraphe</strong> (K-line), le <strong>téléchargement à distance du fichier</strong>
                    du tachygraphe, la prise en charge de divers dispositifs tiers RS232 ou RS485
                    et le Dual-SIM ou eSIM, maximiseront l'efficacité de votre flotte.
                </p>

                <p>
                    Le terminal convient à des applications telles que la logistique
                    internationale, le transport réfrigéré, l'agriculture, la construction et
                    l'exploitation minière, la sécurité et les services d'urgence, etc.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>
                <ul class="ml-5">
                    <li>contrôle de la chaîne du froid</li>
                    <li>Suivi des véhicules terrestres et des actifs</li>
                    <li>Suivi du tachygraphe numérique</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/FMB640_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">

                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>104,1 x 76,8 x 31,5 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>197g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-40°C à +85°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP41</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------Fin FMB640-2G------------------------------->




            <!------------------------Debut FMM640–4G-CAT-M1------------------------------->
            <div *ngIf="param==='FMM640–4G-CAT-M1'">

                <div class="section-title">
                    <h1>FMM640 – 4G CAT M1</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise FMM640 est un tracker avec connectivité LTE CAT M1, NB IoT, qui
                    rend cet appareil utilisable avec les <strong>technologies les plus récentes et les
                    plus rentables.</strong>
                </p>
                <p>
                    Les caractéristiques du FMM640, telles que les  <strong>données CAN FMS </strong> (J1939),
                    les  <strong>données CAN carburant </strong> (J1708), les  <strong>données en direct du tachygraphe </strong>
                    (K-line), le  <strong>téléchargement à distance du fichier </strong> du tachygraphe, la prise
                    en charge de divers dispositifs tiers RS232 ou RS485 et la double carte SIM
                    maximisent l’efficacité de votre flotte.
                </p>

                <p>
                    Le terminal convient à des applications telles que la logistique
                    internationale, le transport réfrigéré, l’agriculture, la construction et
                    l’exploitation minière, les services de sécurité et d’urgence et bien plus
                    encore.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>

                <ul>
                    <li>Contrôle de la chaîne du froid.</li>
                    <li>Suivi des véhicules terrestres et des actifs.</li>
                    <li>Suivi du tachygraphe numérique.</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/FMM640_4G_CAT_M1.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">
                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>4G CAT M1 (FMM640), 4G LTE (FMC640)</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>104,1 x 76,8 x 31,5 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>197g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-40°C à +85°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP41</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------FIN FMM640–4G-CAT-M1------------------------------->




        </div>






    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h1>Les autres solutions</h1>
        </div>
        <div class="row justify-content-center">
            <app-other-solutions [param]="param"></app-other-solutions>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->
