import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-schema-expertise',
  templateUrl: './schema-expertise.component.html',
  styleUrls: ['./schema-expertise.component.scss']
})
export class SchemaExpertiseComponent implements OnInit {

  constructor( private titleService: Title ) {
    this.titleService.setTitle('Ha2mTechnology');
  }

  ngOnInit(): void {
  }

  pageTitle = [
    {
      pageTitle: `How It Works`
    }
  ]
  timelineContent = [
    {
      number: `01`,
      title: `Tracking et géolocalisation de vos véhicules`
    },
    {
      number: `02`,
      title: `Analyses de crash`
    },
    {
      number: `03`,
      title: `Assistance et gestion des vols de véhicules`
    },
    {
      number: `04`,
      title: `Analyse du comportement de conduite de vos chauffeurs`
    },
    {
      number: `05`,
      title: `Détection des fraudes`
    },
    {
      number: `06`,
      title: `Réduction des coûts de maintenance et de consommation`
    },
    {
      number: `07`,
      title: `Gestion automatiques des programmes de maintenances de vos véhicules`
    },
    {
      number: `07`,
      title: `Analyse et rapport de gestion du carburant`
    }
  ]

}
