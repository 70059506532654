<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Valued Clients</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Clients Area -->
<div class="clients-area ptb-100">
    <div class="container">
        <div class="clients-content">
            <img src="../../../../assets/img/others/clients-map.png" alt="image">
            <h3>Contact us for a list of references</h3>
            <p><strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. We offer professional security solutions for business.</strong></p>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            <p><strong>1.</strong> Sony PlayStation 4 Pro – Best Overall<br>
                <strong>2.</strong> Xbox One S – Best Gaming Console for Multimedia<br>
                <strong>3.</strong> Nintendo Switch – Best Hybrid Gaming Console<br>
                <strong>4.</strong> Nintendo Switch Lite – Best for Portable Play
            </p>
        </div>
    </div>
</div>
<!-- End Clients Area -->

<!-- Start Partner Area -->
<div class="partner-area bg-f5f5f5 ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Trusted by Top Brands</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <app-partner></app-partner>
    </div>
</div>
<!-- End Partner Area -->

<app-lets-talk></app-lets-talk>