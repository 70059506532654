<!-- Start Top Header Area -->
<div class="top-header-area {{topHeaderClass}}">
    <div class="container-fluid {{containerClass}}">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <ul class="top-header-contact-info">
                    <li><a href="tel:+44458895456"><i class='bx bx-phone-call'></i> +224 623834552</a></li>
                    <li><a href="mailto:contact@ha2mtechnology.com"><i class='bx bx-envelope'></i>contact@ha2mtechnology.com</a></li>
                </ul>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="top-header-right">
                    <ul class="d-flex align-items-center justify-content-end social-links-bis">
                        <li>
                            <div class="dropdown language-switcher d-inline-block" [class.active]="switcherClassApplied">
                                <button class="dropdown-toggle" type="button" (click)="switcherToggleClass()">
                                    <img src="assets/img/flag/france.jpg" alt="flag">
                                    <span>FR <i class='bx bx-chevron-down'></i></span>
                                </button>
                                <div class="dropdown-menu">
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/us.jpg" alt="image">
                                        <span>EN</span>
                                    </a>
                                </div>
                            </div>
                        </li>

                        <li class="social-media-header"><a href="https://www.facebook.com/people/HA2M-Technology/100091055071079/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li class="social-media-header"><a href="https://twitter.com" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li class="social-media-header"><a href="https://www.linkedin.com" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li class="social-media-header"><a href="https://www.instagram.com" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                    </ul>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Top Header Area -->