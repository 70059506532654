<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Balises basiques</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">

        <div class="ptb-10">

            <!------------------------Debut FMT100 – 2G------------------------------->
            <div *ngIf="id==='FMT100-2G'">

                <div class="section-title">
                    <h1>FMT100 – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise FMT100 est un petit tracker GNSS intelligent et étanche avec
                    connectivité Bluetooth et batterie de secours interne. Elle est équipée d’un
                    connecteur à pince spécial pour une connexion rapide du câble
                    d’alimentation à la batterie de la voiture (montage directement sur la
                    batterie du véhicule).</p>
                <p>Un boîtier étanche solide et des capteurs d’accéléromètre et de gyroscope
                    intégrés avec une fonctionnalité de trace d’accident extrêmement précise
                    rendent cet appareil parfaitement adapté aux solutions du marché de
                    l’assurance.</p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>
                <ul class="ml-5">
                    <li>Identification sans fil des conducteurs et suivi du temps de
                        travail grâce aux balises d'identification BLE</li>
                    <li>Track and trace de base</li>
                    <li>Solution de conduite écologique</li>
                    <li>Solution de détection de l'excès de vitesse, du brouillage, de la
                        marche au ralenti excessive, du débranchement, du remorquage</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/FMT100_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">

                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>92,5 x 57,6 x 14 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>63g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-40°C à +85°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP65</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------Fin FMT100 – 2G------------------------------->




            <!------------------------Debut FMB920 – 2G------------------------------->
            <div *ngIf="id==='FMB920-2G'">

                <div class="section-title">
                    <h1>FMB920 – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise FMB920 est un tracker compact et intelligent avec connectivité
                    Bluetooth, antennes internes GNSS et GSM à gain élevé et batterie de
                    secours intégrée. Le FMB920 est conçu pour le suivi des véhicules légers
                    dans des applications telles que la télématique d’assurance, les voitures de
                    location, la récupération de voitures volées, les services de sécurité publique,
                    le transport de livraison, le taxi et bien plus encore.
                    </p>
                <p>Les entrées / sorties étendent les scénarios d’utilisation des appareils.
                    L’entrée numérique peut être utilisée pour la surveillance de l’état de
                    l’allumage, de la porte ou du bouton d’alarme. L’immobilisation à distance
                    du véhicule peut être réalisée à l’aide de la sortie numérique FMB920.</p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>

                <ul>
                    <li>Track and trace de base.</li>
                    <li>Solution de conduite écologique.</li>
                    <li>Solution antivol et récupération de véhicules volés.</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/FMB920_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">
                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>79 x 43 x 12 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>54g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-40°C à +85°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP54</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------FMB920 – 2G------------------------------->




            <!------------------------Debut FMM920 – 4G------------------------------->
            <div *ngIf="id==='FMM920-4G'">

                <div class="section-title">
                    <h1>FMM920 – 4G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    Le FMM920 est un petit tracker intelligent doté de la connectivité Bluetooth,
                    d'antennes GNSS et GSM à haut gain internes et d'une batterie de secours
                    intégrée. La technologie 4G offre une bande passante beaucoup plus large,
                    une latence plus faible et une meilleure efficacité du spectre.
                </p>
                <p>
                    Le modèle est compact, fiable, précis et endurant. Toutes les interfaces dont
                    il dispose sont plus que suffisantes pour un suivi de base. L'entrée
                    numérique peut être utilisée pour surveiller l'état de l'allumage, de la porte
                    ou du bouton d'alarme, tandis que l'immobilisation à distance du véhicule
                    peut être réalisée en utilisant la sortie numérique de l'appareil. L'entrée
                    analogique étend également les scénarios d'utilisation de l'appareil avec la
                    température, l'humidité, la surveillance du niveau de liquide et bien d'autres
                    choses encore.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>

                <ul>
                    <li>Track and trace de base.</li>
                    <li>Solution de conduite écologique.</li>
                    <li>Solution antivol et récupération de véhicules volés.</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/FMM920_4G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">
                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>4G CAT M1</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>79 x 43 x 12 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>54g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-20°C à +40°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP54</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------FMM920 – 4G------------------------------->

        </div>






    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h1>Les autres solutions</h1>
        </div>
        <div class="row justify-content-center">
           <app-other-solutions [param]="param"></app-other-solutions>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->