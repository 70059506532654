import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-home-banner',
    templateUrl: './home-banner.component.html',
    styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    mainBannerContent = [
        {
            bgImage: `assets/img/others/image-home.png`,
            title: `HA2M TECHNOLOGY`,
            paragraph: ``,
            /*buttonBox: [
                {
                    buttonText: `GET STARTED`,
                    buttonIcon: `bx bxs-chevron-right`,
                    buttonLink: `contact`
                },
                {
                    buttonText: `SCHEDULE A DEMO`,
                    buttonIcon: `bx bxs-chevron-right`,
                    buttonLink: `contact`
                }
            ]*/
        }
    ]

}