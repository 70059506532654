import { Component, OnInit } from '@angular/core';
import  packageJson from '../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  version : any;

  constructor() { }



  ngOnInit(): void {
     this.version = packageJson.version;
  }

}
