import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-balises-avancees',
  templateUrl: './balises-avancees.component.html',
  styleUrls: ['./balises-avancees.component.scss']
})
export class BalisesAvanceesComponent {

  param : string;

  constructor(private route : ActivatedRoute) {
    //this.id = this.route.snapshot.paramMap.get("id");
    this.route.params.subscribe(params => {
      this.param = params['id'];
    });
  }

  ngOnInit(): void {

  }

}
