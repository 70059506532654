import {Component} from '@angular/core';

@Component({
  selector: 'app-punch-lock',
  templateUrl: './punch-lock.component.html',
  styleUrls: ['./punch-lock.component.scss']
})
export class PunchLockComponent {

}
