import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-personal-tracker',
  templateUrl: './personal-tracker.component.html',
  styleUrls: ['./personal-tracker.component.scss']
})
export class PersonalTrackerComponent {
  param : string;

  constructor(private route : ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.param = params['id'];
    });
  }

  ngOnInit(): void {

  }
}
