<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1 *ngFor="let Title of pageTitle;">{{Title.pageTitle}}</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<div class="services-details-area ptb-70">
    <div class="container">
        <div class="row ">
            <div class="col-lg-6 col-md-12">
                <h2>Module de tracking et de géolocalisation</h2>
                <div class="services-details-content">
                    <!--<h2>Module de tracking et de géolocalisation</h2>-->
                    <ul>
                        <li>Géolocalisation : cartographie mondiale avec vue satellite Google Maps, Google Street & Open Street Map</li>
                        <li>Fonction Geofencing en cercle, polygone et couloir (délimitation d’une zone d’intérêt)</li>
                        <li>Gestion du temps de travail : calcul des temps de conduite, de repos, etc.</li>
                        <li>Génération de rapports paramétrables (trajets, arrêts, carburant, etc.) avec export en PDF et CSV.</li>
                        <li>Programmation d’alertes et alarmes (SMS, mail, pop-up, etc.)</li>
                        <li>Module de gestion automatique de la maintenance des véhicules</li>
                        <li>Module de gestion de fuel (rapports et alertes de pleins, siphonage, niveau bas,...)</li>
                        <li>Module d’Éco Driving (excès de vitesse, chocs, tournants brusque,...)</li>
                        <li>Interfaçage facile avec un logiciel existant (Web Services, MDTP, etc.)</li>
                        <li>Identification chauffeur/passager</li>
                        <li>Gestion des statuts (repos, attente, travail, conduite, etc.)</li>
                        <li>Sécurisation du transport avec alerte agression et mode poursuite</li>
                        <li>Programmation des itinéraires</li>
                        <li>Analyses des activités sur vos points d’intérêts (entrepôts, clients, sièges, parking, etc...)</li>
                        <li>Implémentation de vos cartographies (mines, zone industrielle, port ,.....)</li>
                        <li>Suivi en temps réel de votre flotte sur ordinateur et smartphone (Apple et Android)</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-12">
                        <img src="assets/img/expertise/geo-traking.png" alt="image" >
                    </div>

                    <div class="col-12">
                        <img src="assets/img/expertise/track1.PNG" alt="image" >
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>


<div class="services-area pt-70 pb-70 bg-F9FAFB">
    <div class="container">
        <h4 class="text-center">Avantages de notre solution</h4>
        <div class="row mt-5">
            <div class="col-lg-6 col-md-12">
                <div class="services-details-content">
                    <ul>
                        <li> Tracking en temps réel – vue sur carte </li>
                        <li> Cartes SIM Roaming International </li>
                        <li> Possibilité de créer des groupes de véhicules et groupes d’utilisateurs
                            pour la confidentialité des données sur certains véhicules </li>
                        <li> Plages horaires personnalisées pour la vie privée </li>
                        <li> Modules Eco-driving pour sensibiliser les chauffeurs </li>
                        <li> Modules maintenance pour gérer les entretiens du parc automobile </li>
                        <li> Possibilité d’envoyer l’anti-démarrage à distance </li>
                        <li> Caméras intelligentes avec détection de violations </li>
                        <li> Rapports d’excès de vitesse </li>
                        <li> Webservices disponibles pour l’intégration des données dans un autre système </li>
                        <li> Rapports automatisés (journalier / hebdomadaire / Mensuel par mail </li>
                        <li> Alertes en cas de sortie du territoire, en cas d’accident ou de danger </li>
                        <li> Possibilité d’identification chauffeur avec badging </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <img src="../../../../../assets/img/expertise/track4.png" alt="image" >
            </div>
        </div>

        <div>
            <img src="../../../../../assets/img/expertise/track2.PNG" alt="image" style="width: 100%" >
        </div>
    </div>
</div>

<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Les autres services</h2>
        </div>
        <app-tab-domaine-expertise></app-tab-domaine-expertise>
    </div>
</div>

<app-partner></app-partner>



<!--<app-testimonials></app-testimonials>-->

<!-- End Services Details Area -->