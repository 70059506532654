import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-services-page',
    templateUrl: './services-page.component.html',
    styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

    constructor( private titleService: Title) {
        this.titleService.setTitle('ha2mTechnology - domaines expertises');
    }

    ngOnInit(): void {

    }


}