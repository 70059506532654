<!-- Start Services Area -->
<div class="{{mainServicesClass}}">
    <div class="container">
        <div class="section-title" *ngFor="let Content of sectionTitle;">
            <h1>{{Content.title}}</h1>
            <!--<p>{{Content.paragraph}}</p>-->
        </div>

        <div>
            <h2 class="pb-3">Management des Grands Comptes Internationaux</h2>

            <ul>
                <li >Intervention directe de Viasat Connect en Afrique pour les déploiements > 50 véhicules</li>
                <li>Coordination du paramétrage de l’application pour une vision globale au siège</li>
                <li>Mise à disposition des données brutes via les Web Services pour l’intégration au système d’information</li>
                <li>Relation directe avec Viasat pour la valorisation des données collectées</li>
                <li>Equipe R&D à Bruxelles pour la création de solutions personnalisées (RFID active, suivi des objets mobiles transportés)</li>
                <li>Intégration des données cartographiques particulières (SIG)</li>
            </ul>


            <h2 class="pb-3">Un réseau de partenaire certifiés</h2>

            <div class="row">
                <div class="col">
                    <ul>
                        <li>Démonstrations personnalisées</li>
                        <li>Assistance Pilotes / Evaluations</li>
                        <li>Déploiement de grandes flottes</li>
                    </ul>
                </div>


                <div class="col">
                    <ul>
                        <li>Services d'installation</li>
                        <li>Contrat de maintenance</li>
                        <li>Formation utilisateur & support</li>
                    </ul>
                </div>

                <div class="col">
                    <img src="../../../../assets/img/others/certified.jpeg" alt="image" style="width: 20%;">
                </div>
            </div>


        </div>

        <!--<div class="main-services-slides pt-70">
            <owl-carousel-o [options]="mainServicesSlides">
                <ng-template carouselSlide *ngFor="let Content of singleMainServices;">
                    <div class="single-main-services">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12 content">
                                <div class="inner-content">
                                    <h3>{{Content.title}}</h3>
                                    <div *ngFor="let Desc of Content.paragraphList">
                                        <p [innerHTML]="Desc.paragraph"></p>
                                    </div>
                                    <a routerLink="/{{Content.buttonLink}}" class="default-btn"><i class='bx bx-right-arrow-alt'></i>{{Content.buttonText}}</a>
                                </div>
                            </div>
        
                            <div class="col-lg-6 col-md-12 image">
                                <img [src]="Content.img" alt="image">
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>-->
    </div>
</div>
<!-- End Services Area -->