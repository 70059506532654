<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Punchclock (pointeuse mobile)</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">
        <div class="page-title-content ptb-50">
            <h2>Punchclock (pointeuse mobile)</h2>
        </div>
        <p>Une solution INNOVANTE pour gérer le personnel de TERRAIN et sur chantier</p>


        <div class="row ">

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/solutions/Punchclock2.png" alt="image" >
            </div>

            <div class="col-lg-6  col-md-12">
                <div class="services-details-content">

                    <ul>
                        <li>Package prêt à l’emploi ( batterie de 1 mois)</li>
                        <li>Solution de pointage à distance simple</li>
                        <li>RAPPORTS sur les HEURES TRAVAILLEES détaillées pour chaque membre du personnel, ou Groupe de personnes</li>
                        <li>RAPPORTS par SITE DE TRAVAIL, basés sur une ou plusieurs pointeuses pour un même site</li>
                    </ul>

                </div>
            </div>


        </div>


    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Les autres solutions</h2>
        </div>
        <div class="row justify-content-center">
            <app-featured-services></app-featured-services>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->