<app-home-banner></app-home-banner>

<app-home-features></app-home-features>

<app-main-services></app-main-services>

<!--<app-why-choose-us></app-why-choose-us>-->

<div class="partner-area pb-5">
    <div class="container">
        <div class="section-title">
            <h1>Nos domaines d'expertises</h1>
        </div>
        <app-schema-expertise></app-schema-expertise>
    </div>
</div>

<app-services></app-services>

<!--<app-testimonials></app-testimonials>-->

<app-funfacts></app-funfacts>

<!--<app-team-style-one></app-team-style-one>-->

<app-partner></app-partner>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h1>Les autres solutions</h1>
        </div>
        <div class="row justify-content-center">
            <app-other-solutions></app-other-solutions>
        </div>
    </div>
</div>

<!--<app-blog></app-blog>

<app-lets-talk></app-lets-talk>-->