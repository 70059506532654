import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { nodemailer } from 'nodemailer';



@Injectable({
  providedIn: 'root'
})
export class SendMailService {

  constructor(private httpClient : HttpClient) {
  }

  sendMail(formData : any) {
    //return this.httpClient.post<any>('http://localhost:8090/getdetails', dataset);
    return  this.httpClient.post('http://localhost:3000/send-email', formData);
  }

 /* async sendEmail(data : any) {

    //console.log("afficher ", process.env)
    // Configuration du transporter Nodemailer
    const transporter = nodemailer.createTransport({
      host: 'smtp.hostinger.com', // Remplacez par le serveur SMTP que vous utilisez
      port: 587, // Port SMTP (587 est couramment utilisé)
      secure: false, // true pour le port 465, false pour 587
      auth: {
        user: 'contact@ha2mtechnology.com', // Votre adresse e-mail
        pass: 'ha2:!m*cont:*acT24:!$*' // Votre mot de passe e-mail
      }
    });

    // Configuration de l'e-mail
    const mailOptions = {

      from: {
        name: data.name,
        address: 'contact@ha2mtechnology.com',
      },
      to: 'contact@ha2mtechnology.com',
      subject: data.subject,
      text: `Nom : ${data.name}\nEmail : ${data.recipient}\nMessage : ${data.msgBody}`,
      replyTo: data.recipient
    };

    try {
      // Envoi de l'e-mail
      const info = await transporter.sendMail(mailOptions);
      console.log('E-mail envoyé avec succès :', info.response);
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'e-mail :', error);
    }
  }*/
}
