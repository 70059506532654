import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-funfacts',
    templateUrl: './funfacts.component.html',
    styleUrls: ['./funfacts.component.scss']
})
export class FunfactsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    singleFunfacts = [
        {
            //icon: `flaticon-suitcase`,
            icon : 'bi bi-car-front',
            number: `25 000`,
            sign: `+`,
            subText: `véhicules`
        },
        {
            icon: `flaticon-handshake`,
            number: `200`,
            sign: `+`,
            subText: `clients en Europe et en Afrique`
        },
        {
            icon: `flaticon-refresh`,
            number: `20 ans`,
            subText: `dans la fabrication de matériels GPS et de plateformes de géolocalisation`
        },
        {
            icon: `flaticon-group`,
            number: `10 000`,
            sign: `+`,
            subText: `véhicules connectés en Afrique`
        }
    ]

}