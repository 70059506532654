<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Personal Tracker</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">

        <div class="ptb-10">

            <!------------------------Debut IConnect – 2G------------------------------->
            <div *ngIf="param==='ICONNECT-2G'">

                <div class="section-title">
                    <h1>IConnect – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    L’IConnect est un tracker personnel autonome avec une connectivité GNSS,
                    GSM et Bluetooth. L'appareil est conçu pour tous ceux qui exercent une
                    <strong>activité sans la surveillance étroite ou directe d'autres personnes :</strong> par
                    exemple, les visiteurs médicaux, les agents d'entretien, les travailleurs isolés
                    ou les employés travaillant en dehors des heures normales de travail.
                </p>
                <p>
                    Le tracker offre une communication vocale de haute qualité, des indications
                    LED programmables et une batterie de 1050mAh pour répondre aux
                    dernières législations en matière de sécurité et de protection des
                    travailleurs isolés.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>
                <ul class="ml-5">
                    <li>Protection et suivi des travailleurs</li>
                    <li>Sécurisation des personnes isolées</li>
                    <li>Maintien des distanciations sociales</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/IConnect_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">

                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>95 x 64 x 11 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>80g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-20°C à +60°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP30</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------Fin IConnect – 2G------------------------------->




            <!------------------------Debut TMT250 – 2G------------------------------->
            <div *ngIf="param==='TMT250-2G'">

                <div class="section-title">
                    <h1>TMT250 – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    Le TMT250 est un tracker personnel autonome avec une connectivité GNSS,
                    GSM et Bluetooth. Notre mini tracker est conçu pour la <strong>surveillance des
                    personnes, des animaux domestiques et des voitures</strong>. Il peut également
                    être appliqué pour le contrôle des employés, le personnel de sécurité lors
                    des événements sportifs et d'autres cas d'utilisation pour <strong>assurer la
                    sécurité et le contrôle</strong>.
                </p>
                <p>
                    Le boîtier étanche IP67 permet une utilisation pratique, même dans des
                    conditions difficiles.
                </p>

                <p>
                    La <strong>grande capacité de la batterie</strong> élargit la gamme d'applications où une
                    longue durée de vie de la batterie est nécessaire.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>

                <ul>
                    <li>suivi de personnes, d’animaux et de voitures</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/TMT250_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">
                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>4G CAT M1 (FMM640), 4G LTE (FMC640)</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>44 x 43 x 20 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>40G</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-20°C à +58°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP67</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------FIN TMT250 – 2G------------------------------->




        </div>






    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Les autres solutions</h2>
        </div>
        <div class="row justify-content-center">
            <app-other-solutions [param]="param"></app-other-solutions>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->
