<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Gestion de carburant</h1>
        </div>
    </div>
</div>


<div class="services-details-area ptb-70">

    <div class="container">

        <div class="text-center ptb-50">
            <h2>Module de gestion de carburant</h2>
        </div>

        <div class="row ">
            <div class="col-lg-6 col-md-12">
                <div class="services-details-content">

                    <ul>
                        <li>
                            Gestion & suivi des dépenses de carburant
                            <ul>
                                <li>
                                    Comparez automatiquement vos dépenses de carburant par rapport au kilométrage parcouru par vos véhicules
                                </li>
                                <li>
                                    Identifier les abus de carte carburant et luttez contre les pratiques prohibés et éradiquez
                                    les comportements nuisibles à votre image
                                </li>
                                <li>
                                    Rationnalisez votre budget carburant en optimisant le suivi, en réalisant des analyses
                                    circonstanciées, en mettant en place des actions ciblées et en réduisant globalement vos coûts de carburant
                                </li>
                            </ul>
                        </li>
                    </ul>


                    <div class="why-choose-us-content">
                        <ul class="features-list mb-5">
                            <li>
                                <div class="icon">
                                    <i class="bi bi-fuel-pump-fill"></i>
                                </div>
                                <p class="ptb-20"> Le Vol de carburant, un véritable fléau !</p>
                            </li>
                        </ul>
                    </div>

                        <ul class="mt-3">

                            <li>
                                Suivi du niveau de carburant
                                <ul>
                                    <li>Alerter les niveaux de pleins minimum pour éviter les pannes d’essence</li>
                                    <li>Restez alerté en cas de vol: siphonnage ou sur remplissage (détection dès 2%)</li>
                                </ul>
                            </li>
                            <li>
                                Sensibilisation des chauffeurs sur leur gestion du carburant
                            </li>
                            <li>
                                Générez les rapports détaillés; siphonage, niveau de carburant, rapport de plein
                            </li>
                        </ul>





                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/expertise/carb1.png" alt="image" >
                <img src="assets/img/expertise/carb3.png" alt="image" >
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/expertise/carb4.PNG" alt="image" style="width: 100%">
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/expertise/carb2.png" alt="image" style="width: 100%">
            </div>

        </div>

    </div>

</div>


<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Les autres services</h2>
        </div>
        <app-tab-domaine-expertise></app-tab-domaine-expertise>
    </div>
</div>

<app-partner></app-partner>
