<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Qui sommes-nous?</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="about-image text-center">
                    <img src="../../../../assets/img/others/image-1.PNG" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="about-image text-center">
                    <img src="../../../../assets/img/others/image-2.PNG" alt="image">
                </div>
            </div>
        </div>

        <div class="about-content">

            <!--<h2>Qui sommes-nous?</h2>-->
            <!--<span class="sub-title">Nous connaître</span>-->
            <p>
                <strong>HA2M Technology</strong> est une Startup (entreprise innovante à fort potentiel de croissance) guinéenne
                évoluant dans le domaine de la technologie télématique les plus modernes, la gestion de la flotte et
                du Big Data, les systèmes de sécurité anti vol, le suivi du personnel mobile et de l’IoT (l’Internet des
                Objets).
            </p>

            <p>
                Chez <strong>HA2M Technology</strong>, nous sommes convaincus que la digitalisation doit permettre de
                s’affranchir de la distance.
            </p>
            <p>
                C’est avec cette conviction que nous développons, commercialisons et intégrons des solutions
                technologiques qui visent à augmenter la productivité et la sécurité des ressources mobiles des
                entreprises.
            </p>


            <p>
                Nos solutions rapprochent nos clients en temps réel de toutes leurs ressources terrain, leur
                garantissant une maitrise de leurs véhicules, leurs personnels et leurs équipements en mobilité.
            </p>

            <p>
                Grâce à notre large connaissance sectorielle, nous accompagnons nos clients dans leur volonté de
                modernisation de leur métier, en alliant audace, agilité et accessibilité.
            </p>

            <p>
                <strong>HA2M Technology</strong> est une filiale en Guinée du groupe belge Viasat Connect, spécialisé dans la
                fabrication de matériels GPS et de plateformes de géolocalisation depuis plus de 20 ans.
            </p>

            <p>
                Le groupe <strong>Viasat Connect</strong> est un leader européen en matière de systèmes de sécurité par satellite
                intégrant les technologies télématiques les plus modernes et l&#39;IoT afin d&#39;assurer une sécurité
                complète du véhicule et de ses occupants sur les marchés des systèmes de sécurité antivol, de la
                télématique d&#39;assurance, de la gestion de flotte et du Big Data.
            </p>

            <p>
                Depuis 2014, le groupe a grandi pour devenir l&#39;une des sociétés économiques les plus puissantes,
                affichant sa présence dans plus de <strong>50 pays en Europe, en Afrique, au Moyen-Orient et en Amérique
                du Sud.</strong>
            </p>

            <p>
                Le groupe gère au niveau international près de <strong>700 000 véhicules satellites, 7,6 millions de
                personnes connectées (applications, appareils portables, gestion de la main-d&#39;œuvre), 400 villes
                connectées avec des systèmes de transport intelligents et 6 500 bâtiments comptant plus de 700
                employés.</strong>
            </p>

            <h3 class="text-center">CHIFFRES CLÉS</h3>

            <div class="container mt-4 text-center">
                <div class="row">
                    <div class="col-md-4">
                        <div class="circle-statistic">
                            <div class="circle-number">400</div>
                            <div class="circle-icon">+</div>
                        </div>
                        <div class="circle-label text-center">VILLES CONNECTÉS</div>
                    </div>
                    <div class="col-md-4">
                        <div class="circle-statistic">
                            <div class="circle-number">7.600.000</div>
                            <div class="circle-icon">+</div>
                        </div>
                        <div class="circle-label">PERSONNES CONNECTÉS</div>
                    </div>
                    <div class="col-md-4">
                        <div class="circle-statistic">
                            <div class="circle-number">700.000</div>
                            <div class="circle-icon">+</div>
                        </div>
                        <div class="circle-label">VÉHICULES CONNECTÉS</div>
                    </div>
                </div>


                    <div class="line2 row">
                        <div class="col-md-4">
                            <div class="circle-statistic">
                                <div class="circle-number">35</div>
                                <div class="circle-icon">+</div>
                            </div>
                            <div class="circle-label">PERSONNES FORMIDABLES DANS L’EQUIPE, PRÊTS À VOUS AIDER</div>
                        </div>
                        <div class="col-md-4">
                            <div class="circle-statistic">
                                <div class="circle-number">20</div>
                                <div class="circle-icon">+</div>
                            </div>
                            <div class="circle-label">PLUS DE 20 ANS D’EXPÉRIENCES DANS LE DOMAINE DU TRACKING</div>
                        </div>
                        <div class="col-md-4">
                            <div class="circle-statistic">
                                <div class="circle-number">120</div>
                                <div class="circle-icon">+</div>
                            </div>
                            <div class="circle-label">REVENDEURS CERTIFIÉS EN EUROPE ET EN AFRIQUE</div>
                        </div>
                    </div>


            </div>



            <h3>Présence en Afrique</h3>
            <p>
                Fort d’une expérience de plus de 15 ans en Afrique, le groupe Viasat Connect se positionne comme
                leader du marché de la géolocalisation en Afrique Centrale et de l’Ouest avec plus de 20.000
                véhicules connectés.
            </p>
            <div class="">
                <div class="about-image text-center">
                    <img src="../../../../assets/img/others/afrique1.png" alt="image" style="width: 100%;">
                </div>
            </div>


            <app-partner></app-partner>

    </div>
</div>
</div>
<!-- End About Area -->