<!-- Start Team Area -->
<div class="team-area ptb-100">
    <div class="container">
        <div class="section-title" *ngFor="let Content of sectionTitle;">
            <span class="sub-title">{{Content.subTitle}}</span>
            <h2>{{Content.title}}</h2>
        </div>

        <div class="team-slides">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide *ngFor="let Content of singleTeamBox;">
                    <div class="single-team-box">
                        <div class="image">
                            <img [src]="Content.img" alt="image">
                            <span class="share-btn"><i class='bx bx-share-alt'></i></span>
                            <ul class="social">
                                <li *ngFor="let SocialItem of Content.social"><a href="{{SocialItem.link}}" class="{{SocialItem.extraClass}}" target="_blank"><i class='{{SocialItem.icon}}'></i></a></li>
                            </ul>
                        </div>
                        <div class="content">
                            <h3>{{Content.name}}</h3>
                            <span>{{Content.designation}}</span>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
<!-- End Team Area -->