import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-partner',
    templateUrl: './partner.component.html',
    styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {


    europePartners: string[] = ['ajimex.png', 'alliance.png', 'bergerat.jpeg', 'cegelec.jpeg', 'charleroi.png',
        'cnockaert.jpeg', 'cs.png', 'culturefood.png', 'equans.jpeg', 'gkw.jpeg', 'jordan.jpeg', 'liege.png',
    'renault.png', 'thomaspiron.jpeg', 'total.jpeg', 'tpalm.jpeg', 'tradimex.jpeg', 'vhc.jpeg'];

    afriquePartners: string[] = ['alios.jpg', 'aml.png', 'atc.png', 'banqueAtlantique.jpg', 'bbmultiservice.png',
        'canal.png', 'centaures.jpg', 'cie.jpg', 'cimencam.jpg', 'cnpsnsif.jpg', 'colas.png', 'douaneCam.JPG',
        'edg.png', 'foraco.jpg', 'health.jpg', 'heineken.png', 'hertz.png', 'horizon.png', 'mercureLogistique.jpg',
        'nocibeSA.png', 'orange.jpg', 'prumental.jpg', 'saph.jpg', 'scbCam.jpg', 'sifcomAssur.jpg', 'skol.png',
        'sobebra.jpg', 'sogb.jpg', 'transfrique.jpg'
    ];

    constructor() { }

    ngOnInit(): void {
    }

    partnerSlides: OwlOptions = {
        loop: true,
		nav: false,
		dots: false,
		margin: 30,
		autoplay: true,
        autoplayTimeout: 4000,
		autoplayHoverPause: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>"
		],
		responsive: {
			0: {
				items: 2
			},
			576: {
				items: 3
			},
			768: {
				items: 4
			},
			992: {
				items: 4
			}
		}
    }

}