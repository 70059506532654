<!-- Start Navbar Area -->
<div class="navbar-area {{navbarClass}}">
    <div class="naxai-responsive-nav">
        <div class="container">
            <div class="naxai-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="../../../../assets/img/others/logo.jpeg" alt="logo" style="width: 35%"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="naxai-nav">
        <div class="container-fluid {{containerClass}}">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="../../../../assets/img/others/logo.jpeg" alt="logo" style="width: 75%"></a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">

                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                                [routerLinkActiveOptions]="{exact: true}">Accueil</a></li>
                        
                        <li class="nav-item"><a routerLink="/domaine-expertise" class="dropdown-toggle nav-link">Nos services</a>

                                <ul class="dropdown-menu">
                                    <li><a routerLink="/domaine-expertise/tacking-geolocalisation" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">Tracking et géolocalisation de vos véhicules</a></li>

                                    <li><a routerLink="/domaine-expertise/crash-detection" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">Détection/Analyses de crash</a></li>

                                    <li><a routerLink="/domaine-expertise/gestion-carburant" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">Gestion de carburant</a></li>


                                    <li><a routerLink="/domaine-expertise/eco-drive" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">ÉCO-DRIVE</a></li>

                                    <li><a routerLink="/domaine-expertise/maintenance" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">Maintenance</a></li>
                                    <li><a routerLink="/domaine-expertise/others-services" routerLinkActive="active"
                                           [routerLinkActiveOptions]="{exact: true}">Autres services</a></li>
                                </ul>

                        </li>


                        <!-----------Debuuuuuuuut--------------------->

                        <li class="nav-item megamenu"><a routerLink="/solution" class="dropdown-toggle nav-link">Nos solutions</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <div class="row">
                                        <div class="col">
                                            <h6 class="submenu-title">Balises basiques</h6>
                                            <ul class="megamenu-submenu">
                                                <li><a [routerLink]="['/solution/balises-basiques', 'FMT100-2G']"
                                                       routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FMT100 – 2G</a></li>

                                                <li><a [routerLink]="['/solution/balises-basiques', 'FMB920-2G']"
                                                       routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FMB920 – 2G</a></li>

                                                <li><a [routerLink]="['/solution/balises-basiques', 'FMM920-4G']"
                                                       routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FMM920 – 4G</a></li>
                                            </ul>

                                            <h6 class="submenu-title">Balises avancées</h6>
                                            <ul class="megamenu-submenu">
                                                <li><a [routerLink]="['/solution/balises-avancees', 'FMB640-2G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">FMB640 – 2G</a></li>

                                                <li><a [routerLink]="['/solution/balises-avancees', 'FMM640–4G-CAT-M1']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">FMM640 – 4G CAT M1</a></li>
                                            </ul>

                                            <h6 class="submenu-title">OBD</h6>
                                            <ul class="megamenu-submenu">
                                                <li><a [routerLink]="['/solution/obd', 'FMB003-2G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">FMB003 – 2G</a></li>
                                                <li><a [routerLink]="['/solution/obd', 'FMM003-4G-CAT-M1']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">FMM003 – 4G CAT M1</a></li>
                                            </ul>

                                        </div>

                                        <div class="col">
                                            <h6 class="submenu-title">Balises spéciales</h6>
                                            <ul class="megamenu-submenu">
                                                <li><a [routerLink]="['/solution/balises-speciales', 'E-Lock-2G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">E-Lock – 2G</a></li>
                                                <li><a [routerLink]="['/solution/balises-speciales', 'PUNCHCLOCK–2G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Punchclock – 2G</a></li>
                                                <li><a [routerLink]="['/solution/balises-speciales', 'CAMERA-INTELLIGENTE–4G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Caméra intelligente – 4G</a></li>
                                            </ul>

                                            <h6 class="submenu-title">Personal Tracker</h6>
                                            <ul class="megamenu-submenu">
                                                <li><a [routerLink]="['/solution/personal-tracker', 'ICONNECT-2G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">IConnect – 2G</a></li>
                                                <li><a [routerLink]="['/solution/personal-tracker', 'TMT250-2G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">TMT250 – 2G</a></li>
                                            </ul>

                                            <h6 class="submenu-title">Balises étanches</h6>
                                            <ul class="megamenu-submenu">
                                                <li><a [routerLink]="['/solution/balises-etanches', 'FMB204-2G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">FMB204 – 2G</a></li>
                                            </ul>
                                        </div>

                                        <div class="col">

                                            <h6 class="submenu-title">Balises autonomes</h6>
                                            <ul class="megamenu-submenu">
                                                <li><a [routerLink]="['/solution/balises-autonomes', 'STANDALONE-2G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Standalone – 2G</a></li>

                                                <li><a [routerLink]="['/solution/balises-autonomes', 'TAT100-2G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">TAT100 – 2G</a></li>

                                                <li><a [routerLink]="['/solution/balises-autonomes', 'AT4–2G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">AT4 – 2G</a></li>
                                            </ul>

                                            <h6 class="submenu-title">Balises «multi-fonctions»</h6>
                                            <ul class="megamenu-submenu">
                                                <li><a [routerLink]="['/solution/balises-multi-fonctions', 'FMB120-2G']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">FMB120 – 2G</a></li>

                                                <li><a [routerLink]="['/solution/balises-multi-fonctions', 'FMM130–4G-CAT-M1']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">FMM130 – 4G CAT M1</a></li>
                                            </ul>

                                            <h6 class="submenu-title">Accessoires</h6>
                                            <ul class="megamenu-submenu">
                                                <li><a [routerLink]="['/solution/accessoires', 'ID-CHAUFFEUR']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Identification chauffeur</a></li>

                                                <li><a [routerLink]="['/solution/accessoires', 'CAN-BUS']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">CAN-bus</a></li>

                                                <li><a [routerLink]="['/solution/accessoires', 'BUZZER-EXCES-VITESSE']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Buzzer excès de vitesse</a></li>

                                                <li><a [routerLink]="['/solution/accessoires', 'BOUTON-PANIC']" routerLinkActive="active"
                                                       [routerLinkActiveOptions]="{exact: true}">Bouton panic</a></li>
                                            </ul>



                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>


                        <!-----------Fiiiiiinnnnn--------------------->

                        <!--<li class="nav-item"><a routerLink="/solution" class="dropdown-toggle nav-link">Nos solutions</a>
                            <ul class="dropdown-menu">
                                <li><a routerLink="/solution/fmb120" routerLinkActive="active"
                                       [routerLinkActiveOptions]="{exact: true}">FMB120</a></li>

                                <li><a routerLink="/solution/hardware-fuel" routerLinkActive="active"
                                       [routerLinkActiveOptions]="{exact: true}">Hardware Fuel</a></li>

                                <li><a routerLink="/solution/balise-e-lock" routerLinkActive="active"
                                       [routerLinkActiveOptions]="{exact: true}">Balise E-LOCK</a></li>

                                <li><a routerLink="/solution/camera-intelligente" routerLinkActive="active"
                                       [routerLinkActiveOptions]="{exact: true}">Caméras intelligentes</a></li>

                                <li><a routerLink="/solution/punch-lock" routerLinkActive="active"
                                       [routerLinkActiveOptions]="{exact: true}">Punchclock (pointeuse mobile)</a></li>
                            </ul>
                        </li>-->

                        <li class="nav-item"><a routerLink="/references" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Nos références</a></li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Nous Connaître</a></li>

                        <li class="nav-item"><a routerLink="/gallery" class="nav-link"
                                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Gallerie</a></li>
                    </ul>

                    <div class="others-option d-flex align-items-center m-lg-3">
                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"><i class='bx bx-right-arrow-alt'></i>Contactez-nous</a>
                        </div>
                    </div>

                </div>
            </nav>
        </div>
    </div>

</div>
<!-- End Navbar Area -->