import { Injectable } from '@angular/core';
import imageList from '../../../assets/gallery/images.json';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor() { }

  getImages():{ img: string }[] {
    return imageList;
  }
}
