<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>OBD</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">

        <div class="ptb-10">

            <!------------------------Debut FMB003 – 2G------------------------------->
            <div *ngIf="param==='FMB003-2G'">

                <div class="section-title">
                    <h1>FMB003 – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise FMB003 est un appareil plug-and-play ultra-petit dédié aux
                    applications OBD de nouvelle génération. La principale caractéristique du
                    FMB003 est sa possibilité de lire les paramètres OEM (PID) via le port OBD.
                    Avec cet appareil, vous pourrez lire les <strong>données du compteur kilométrique
                    réel et du niveau de carburant réel</strong>.
                </p>
                <p>
                    C’est un outil de suivi parfait pour un large éventail de cas d’utilisation - y
                    compris la gestion de flotte de véhicules utilitaires légers, le journal de bord
                    du conducteur, la télématique d’assurance (UBI), la location et la location de
                    voitures et plus encore.
                </p>

                <p>
                    L’appareil prend en charge divers capteurs BLE 4.0, balises, casques mains
                    libres, micrologiciels et mises à jour de configuration via Bluetooth,
                    élargissant ainsi son ensemble déjà riche de fonctionnalités.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>
                <ul class="ml-5">
                    <li>Suivi et surveillance de la voiture familiale avec des dispositifs gps OBD</li>
                    <li>Gestion de carburant</li>
                    <li>Éco conduite</li>
                    <li>Maintenance</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/FMB003_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">

                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>52,6 x 29,1 x 26 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>30G</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-40°C à +85°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP41</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------Fin FMB003 – 2G------------------------------->




            <!------------------------Debut FMM003 – 4G CAT M1------------------------------->
            <div *ngIf="param==='FMM003-4G-CAT-M1'">

                <div class="section-title">
                    <h1>FMM003 – 4G CAT M1</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise FMM003 est un dispositif avancé plug and play LTE CAT-M1 dédié
                    à la prochaine génération d'applications OBD. La principale caractéristique
                    du FMM003 est sa possibilité de <strong>lire les paramètres OEM</strong> (PIDs) via le port
                    OBD. Avec ce dispositif, vous serez en mesure de lire les<strong>données de
                    l'odomètre réel</strong>  et du <strong>niveau de carburant réel</strong> .
                </p>
                <p>
                    C'est un tracker parfait pour un large éventail de cas d'utilisation - y compris
                    la gestion de flotte de véhicules commerciaux légers, le journal de bord du
                    conducteur, la télématique d'assurance (UBI), la location et le leasing de
                    voitures et plus encore.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>

                <ul>
                    <li>Suivi et surveillance de la voiture familiale avec des dispositifs gps OBD</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/FMM003_4G_CAT_ M1.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">
                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>4G CAT-M1</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>67,2 x 49,6 x 25 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>63g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>--40°C à +85°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP41</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------FIN FMM003 – 4G CAT M1------------------------------->




        </div>






    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Les autres solutions</h2>
        </div>
        <div class="row justify-content-center">
            <app-featured-services></app-featured-services>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->
