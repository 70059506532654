import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-tracking-geoloc-details-page',
    templateUrl: './tracking-geoloc-details-page.component.html',
    styleUrls: ['./tracking-geoloc-details-page.component.scss']
})
export class TrackingGeolocDetailsPageComponent implements OnInit {

    constructor( private titleService: Title ) {
    }

    ngOnInit(): void {
    }

    pageTitle = [
        {
            pageTitle: `Tracking et géolocalisation`
        }
    ]
    servicesDetailsContent = [
        {
            title: `Virtual Chief Information Security Officer Services`,
            paragraphList: [
                {
                    paragraph: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.`
                },
                {
                    paragraph: `It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`
                }
            ]
        }
    ]
    servicesDetailsVideo = [
        {
            buttonText: `CONTACT US`,
            buttonLink: `contact`
        }
    ]

}