<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Hardware Fuel</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">
        <div class="page-title-content ptb-50">
            <h2>Hardware Fuel</h2>
        </div>

        <div class="row ">

            <div class="col-lg-4  col-md-12">
                <div class="services-details-content">
                    <!--<h2>Module de tracking et de géolocalisation</h2>-->
                    <ul>
                        <li>
                            <b>Lecteur CANBUS</b>
                            <p>Connection directement sur le CANBUS permettant une lecture directe
                            des données carburant depuis le système électronique du véhicule.
                            </p>
                        </li>

                    </ul>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <figure>
                    <img src="assets/img/solutions/hard-fuel1.png" alt="image" >
                    <figcaption>Lecteur Can LV-CAN200 ou ALL-CAN300</figcaption>
                </figure>
            </div>

            <div class="col-lg-4 col-md-12">
                <figure>
                    <img src="assets/img/solutions/hard-fuel2.png" alt="image" >
                    <figcaption>Simple Can</figcaption>
                </figure>
            </div>

            <div class="col-lg-6  col-md-12 mt-5">
                <img src="assets/img/solutions/hard-fuel3.png" alt="image" >
            </div>

            <div class="col-lg-6  col-md-12 mt-5">
                <ul>
                    <li>
                        <b>Probe</b>
                        <p>
                            Installation d’une sonde dans le réservoir de carburant
                            afin de permettre de monitorer les données carburant.
                        </p>
                    </li>

                </ul>
            </div>

        </div>





    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Les autres solutions</h2>
        </div>
        <div class="row justify-content-center">
            <app-featured-services></app-featured-services>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->