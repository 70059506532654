<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Balises « multi-fonctions »</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">

        <div class="ptb-10">

            <!------------------------Debut FMB120 – 2G------------------------------->
            <div *ngIf="param==='FMB120-2G'">

                <div class="section-title">
                    <h1>FMB120 – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise FMB120 est un petit tracker avec antennes GSM et GNSS internes
                    à gain élevé, qui est capable de collecter les coordonnées de l’appareil et
                    d’autres données utiles et de les transférer via le réseau GSM vers le serveur.
                </p>
                <p>
                    Cet appareil est parfaitement adapté aux applications où l’acquisition
                    d’emplacement d’objets distants est nécessaire : gestion de flotte, sociétés
                    de location de voitures, sociétés de taxi, transports publics, sociétés de
                    logistique, voitures personnelles, etc.
                </p>

                <p>
                    Le FMB120 peut effectuer des tâches sur des objets distants, telles que la
                    surveillance de l’état du moteur, le contrôle de la porte du camion, etc.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>
                <ul class="ml-5">
                    <li>Identification sans fil du conducteur et suivi du temps avec id BLE.</li>
                    <li>Suivi de la température avec des capteurs BLE</li>
                    <li>Suivi et identification automatisés du temps de travail des conducteurs</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/FMB120_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">

                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td> 65 x 56,6 x 18,9 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>55g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-40°C à +85°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP41</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------FMB120 – 2G------------------------------->




            <!------------------------Debut FMM130 – 4G CAT M1------------------------------->
            <div *ngIf="param==='FMM130–4G-CAT-M1'">

                <div class="section-title">
                    <h1>FMM130 – 4G CAT M1</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise FMM130 est un petit terminal de suivi avec connectivité GNSS et
                    LTE CAT-M1 / NB -IoT / GSM et batterie de secours. Appareil équipé de
                    modules GNSS / Bluetooth et LTE CAT-M1 / NB -IoT avec repli sur réseau 2G,
                    antennes internes GNSS et LTE, entrées numériques configurables, entrées
                    analogiques et sorties numériques, entrée négative, entrées
                    impulsionnelles.
                </p>
                <p>
                    Il est parfaitement adapté aux applications où l’acquisition d’emplacement
                    d’objets distants est nécessaire : gestion de flotte, sociétés de location de
                    voitures, sociétés de taxi, transports publics, sociétés de logistique, voitures
                    personnelles, etc.
                </p>


                <h2 class="mt-5">Scénarios d’utilisation :</h2>

                <ul>
                    <li>Identification sans fil du conducteur et suivi du temps avec id BLE.</li>
                    <li>Solution d’autopartage.</li>
                    <li>Calendriers d’entretien de la flotte.</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/FMM130_4G_CAT_M1.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">
                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>4G CAT M1 (FMM130), 4G LTE (FMC130)</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>65 x 56,6 x 20,6 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>55g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-20°C à +85°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP41</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------FIN FMM130 – 4G CAT M1------------------------------->




        </div>






    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h1>Les autres solutions</h1>
        </div>
        <div class="row justify-content-center">
            <app-other-solutions [param]="param"></app-other-solutions>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->
