<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Nos domaines d'expertises</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<div class="services-area pt-100 pb-70">

    <div class="container">

        <div class="about-content">

            <div class="row">

                <div class="col-lg-2 col-md-12">
                    <img src="../../../../assets/img/others/logo-securyat.png" alt="image" style="width: 60%;height: 150px;">
                </div>

                <div class="col-lg-10 col-md-12">
                    <h2>Securysat</h2>
                    <p>L’application de tracking est une solution intelligente et professionnelle pour optimiser
                        la gestion de votre parc de véhicule et des collaborateurs qui les utilisent en temps réel
                        et les protéger contre le vol.</p>
                </div>
            </div>



            <div class="why-choose-area">
                <div class="container">
                    <div class="row align-items-center">

                        <div class="col-lg-6 col-md-12">
                            <div class="why-choose-us-content">
                                <ul class="features-list">
                                    <li>
                                        <div class="icon">
                                            <i class="bi bi-pc-display-horizontal"></i>
                                        </div>
                                        <h3>Securysat, une solution éprouvée</h3>
                                        <p>L’intégralité de la solution (matériel + logiciel) est éprouvée avec plus de 25.000 véhicules
                                            actuellement gérés par plus de 200 clients en Europe et en Afrique.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="why-choose-us-content">
                                <ul class="features-list">
                                    <li>
                                        <div class="icon">
                                            <i class="flaticon-call-center-agent"></i>
                                        </div>
                                        <h3>Assistance permanente</h3>
                                        <p>Tout au long du contrat, vous bénéficiez d’une assistance technique
                                            par téléphone et email, pour profiter de tous les bénéfices de la solution.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="why-choose-us-content">
                                <ul class="features-list">
                                    <li>
                                        <div class="icon">
                                            <i class="bi bi-bookmark-check"></i>
                                        </div>
                                        <h3>Pas de compromis sur la qualité</h3>
                                        <p>Le matériel proposé est fabriqué en Europe, afin de garantir la meilleure qualité
                                            et une durée de vie généralement supérieure à celle des produits concurrents.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="why-choose-us-content">
                                <ul class="features-list">
                                    <li>
                                        <div class="icon">
                                            <i class="flaticon-hacker"></i>
                                        </div>
                                        <h3>Au service de vos besoins</h3>
                                        <p>La conception et l’actualisation de nos logiciels sont intégralement assurées par nos développeurs en interne.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="why-choose-us-content">
                                <ul class="features-list">
                                    <li>
                                        <div class="icon">
                                            <i class="flaticon-internet"></i>
                                        </div>
                                        <h3>Installation professionnelle</h3>
                                        <p>L’installation des équipements dans les véhicules est assurée par des experts agréés
                                            et reconnus dans le milieu de la géolocalisation.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="why-choose-us-content">
                                <ul class="features-list">
                                    <li>
                                        <div class="icon">
                                            <i class="flaticon-innovation"></i>
                                        </div>
                                        <h3>Developpement continu</h3>
                                        <p>Nous investissons en permanence dans
                                            l’amélioration de nos solutions et le développement de nouvelles fonctionnalités.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--<app-tracking-geoloc-details-page></app-tracking-geoloc-details-page>

        <app-crash-detection></app-crash-detection>

        <app-gestion-carburant></app-gestion-carburant>

        <app-eco-drive></app-eco-drive>

        <app-maintenance></app-maintenance>

        <app-others-services></app-others-services>-->

        <div class="services-area pt-100 pb-70">
            <div class="container">

                <div class="section-title">
                    <h2>Nos services</h2>
                </div>

                <app-tab-domaine-expertise></app-tab-domaine-expertise>
            </div>
        </div>

        <app-partner></app-partner>


        <!--<div class="services-area bg-f5f5f5">
            <app-featured-services></app-featured-services>
            <div class="divider"></div>
        </div>-->


        <!--<div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Content of singleServicesBox;">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="{{Content.icon}}"></i>
                    </div>
                    <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                    <p>{{Content.paragraph}}</p>
                    <a routerLink="/{{Content.detailsLink}}" class="link-btn">{{Content.btnText}}<i class='bx bx-chevron-right'></i></a>
                    <div class="back-icon">
                        <i class="{{Content.icon}}"></i>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
</div>
<!-- End Services Area -->

<!-- <app-lets-talk></app-lets-talk>-->