<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Caméras intelligentes</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">
        <div class="page-title-content ptb-50">
            <h2>Caméras intelligentes</h2>
        </div>
        <div class="row ">
            <div class="col-lg-6 col-md-12">
                <div class="services-details-content">
                    <!--<h2>Module de tracking et de géolocalisation</h2>-->
                    <ul>
                        <li>Réduisez le coûts d’assurance de vos véhicules et
                        les contestations en cas d’accidents</li>
                        <li>Réduisez l’immobilisation de vos véhicules</li>
                        <li>Fournissez de l’assistance en direct et évaluez les dégâts</li>
                        <li>Prévenez du vol grâce à la surveillance vidéo du chargement</li>
                        <li>Responsabilisez vos chauffeurs face aux comportements de conduite agressifs</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-12">
                        <img src="assets/img/solutions/camIntelligente.PNG" alt="image" >
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Les autres solutions</h2>
        </div>
        <div class="row justify-content-center">
            <app-featured-services></app-featured-services>
        </div>
    </div>
</div>


<app-partner></app-partner>

<!-- End Page Title Area -->