<!-- Start 404 Error Area -->
<div class="error-area ptb-100">
    <div class="container">
        <div class="error-content">
            <img src="../../../../assets/img/others/error.png" alt="image">
            <h3>Error 404 : Page Not Found</h3>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <a routerLink="/" class="default-btn">BACK TO HOMEPAGE</a>
        </div>
    </div>
</div>
<!-- End 404 Error Area -->