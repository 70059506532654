<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Balises autonomes</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">

        <div class="ptb-10">

            <!------------------------Debut STANDALONE – 2G------------------------------->
            <div *ngIf="param==='STANDALONE-2G'">

                <div class="section-title">
                    <h1>Standalone – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise Standalone est un boîtier de géolocalisation fonctionnant sur
                    batterie (ne nécessite pas d’installation ni de connexion au véhicule). Grâce
                    à ses options de configuration, ce produit dispose d’une extraordinaire
                    flexibilité.
                </p>


                <div>
                    <p>La Standalone existe en plusieurs versions :</p>
                    <ul>
                        <li>
                            <strong>Standard :</strong> le boîtier dispose d’un connecteur mini-USB IP65
                            permettant de recharger la batterie à l’aide d’un chargeur en
                            option ou via l’allume cigare du véhicule, par exemple
                        </li>

                        <li>
                            <strong>12/24V :</strong> le boîtier 12/24V vous permet de recharger directement
                            la batterie sur toute source d’alimentation en 12 ou 24V (par
                            exemple, vous installez le boîtier sur une remorque, une fois celle-
                            ci tractée, la batterie de la Standalone se mettra en charge)
                        </li>
                    </ul>
                </div>

                <p>
                    Le Standalone gère de façon optimale sa batterie. Il passe en mode sommeil
                    dès que son accéléromètre ne détecte plus de mouvement. Il se réveille
                    automatiquement au moindre déplacement.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>
                <div class="row ">
                    <div class="col-lg-6 col-md-12">
                        <div class="services-details-content">
                            <ul class="ml-5">
                                <li>Suivi de remorques.</li>
                                <li>Suivi de transport d’huile de palme.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="row">
                            <div class="col-12">
                                <img src="assets/img/solutions/hardware/Standalone_2G.png" alt="image" >
                            </div>

                        </div>

                    </div>
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">

                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>165 x 85 x 55 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>550g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-20°C à +70°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP65</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------STANDALONE FMT100 – 2G------------------------------->




            <!------------------------Debut TAT100 – 2G------------------------------->
            <div *ngIf="param==='TAT100-2G'">

                <div class="section-title">
                    <h1>TAT100 – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise TAT100 est une toute nouvelle solution de <strong>suivi des biens</strong>. Son
                    boîtier robuste, pratique et étanche (IP68), combiné à différents accessoires
                    de montage, permet une installation extrêmement facile. Développée à
                    partir d'une <strong>puce Bluetooth à faible consommation</strong>, elle offre une longue
                    durée de vie à la batterie.
                </p>
                <p>
                    Les estimations de la durée de vie de la batterie sont influencées par de
                    nombreux facteurs tels que la température extérieure, la fréquence des
                    rapports, l'installation et l'orientation de l'appareil, la couverture réseau, les
                    fonctionnalités supplémentaires (comme le balayage Bluetooth), etc.
                </p>
                    Appairée à un tracker principal, le mode Back up de la balise permet de
                    vérifier périodiquement, via Bluetooth, si le tracker principal est toujours
                    présent. Si la TAT100 ne reçoit aucune réponse du tracker principal, celle-ci
                    envoie une alerte et une position GPS.
                <p>

                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>

                <ul>
                    <li>suivi des biens de valeur, des bobines de fil, des outils, des
                        équipements de construction, des ascenseurs, des conteneurs et
                        de nombreux autres biens non motorisés.</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/TAT100_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">
                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>78 x 63 x 28 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>550g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-20°C à +70°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP65</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------TAT100 – 2G------------------------------->




            <!------------------------Debut AT4 – 2G------------------------------->
            <div *ngIf="param==='AT4–2G'">

                <div class="section-title">
                    <h1>AT4 – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    Ses fonctions avancées, sa construction robuste et sa batterie d'une
                    autonomie inégalée font de l'AT4 la nouvelle référence en matière de suivi
                    GPS de véhicules. Grâce à l'utilisation du GPS et du positionnement LBS, le
                    <strong>traceur de biens</strong> AT4 fournit des rapports détaillés et vous indique
                    exactement où un véhicule a été, où il se dirige et à quelle vitesse il se
                    déplace.
                </p>
                <p>
                    Avec une batterie de 10000mAh et une étanchéité IPX5 pour maximiser les
                    performances, il vous donne le contrôle total des véhicules individuels ou
                    des flottes. Un aimant puissant facilite l'installation tandis que le micro
                    intégré réalise un contrôle vocal.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>

                <ul>
                    <li>Suivi de voiture de location ou de remplacement.</li>
                    <li>Suivi de moto.</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/AT4_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">
                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>108 x 61 x 30 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>285g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-20°C à +70°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IPX5</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------AT4 – 2G------------------------------->

        </div>






    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h1>Les autres solutions</h1>
        </div>
        <div class="row justify-content-center">
            <app-other-solutions [param]="param"></app-other-solutions>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->