<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Détection et analyse des crash</h1>
        </div>
    </div>
</div>

<div class="services-details-area ptb-70">
    <div class="container">
        <!--<div class="text-center ptb-50">
            <h2>Détection des crash</h2>
        </div>-->
        <div class="row ">
            <div class="col-lg-6 col-md-12">
                <div class="services-details-content">
                    <h3 class="mb-5">Les accidents coûtent chers à tous!</h3>
                    <ul>
                        <li>
                            Analyse du comportement de conduite
                        </li>
                        <li>
                            Analyse du temps de conduite
                            <ul class="mt-3">
                                <li>
                                    Vérifiez facilement les temps de trajets par chauffeur et les temps d’arrêts.
                                    Une conduite pendant une période longue augmente le risque d’accident
                                </li>
                            </ul>
                        </li>


                        <li>
                            Alerte et rapport de dépassement de vitesse
                            <ul class="mt-3">
                                <li>Limites de vitesse renseignées par Google</li>
                                <li>Limites de vitesse par véhicule fixée par le client</li>
                                <li>Limites de vitesse dans une zone (POI) fixée par le client</li>
                            </ul>
                        </li>
                        <li>
                            Rapport détaillé en cas d’accident
                            <ul class="mt-3">
                                <li>L’enregistrement des données en cas d’accident se fait toutes les secondes avant
                                    et après l’accident (par défaut 10 sec)</li>
                            </ul>
                        </li>
                        <li>
                            Appuis vidéos si installation Surfsight
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <img src="assets/img/expertise/crash1.jpeg" alt="image" >
            </div>

        </div>

        <div class="text-center">
            <img src="assets/img/expertise/accident.PNG" alt="image" style="width: 70%; height: 500px;">
        </div>
    </div>
</div>


<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Les autres services</h2>
        </div>
        <app-tab-domaine-expertise></app-tab-domaine-expertise>
    </div>
</div>

<app-partner></app-partner>
