import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-gestion-carburant',
  templateUrl: './gestion-carburant.component.html',
  styleUrls: ['./gestion-carburant.component.scss']
})
export class GestionCarburantComponent implements OnInit{

  constructor() {
  }

  ngOnInit(): void {
  }

}
