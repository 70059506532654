import {Component, OnInit} from '@angular/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {SendMailService} from "../../../core/services/send-mail.service";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validator, Validators} from "@angular/forms";

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    url = '';

    contactForm : FormGroup;
    submitted : boolean = false;

    constructor( private titleService: Title, public sanitizer: DomSanitizer,
                 private sendMailService : SendMailService, private fb: FormBuilder) {
        this.titleService.setTitle('ha2m technology - Contact Us');
        this.sanitizer = sanitizer;
    }

    ngOnInit(): void {
        this.contactForm = this.fb.group({
            name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
            email: [null, [Validators.required, Validators.email]],
            telephone: [null],
            subject: [null, Validators.required],
            message: [null, Validators.required],
        });
    }

    getLink(){
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

    submit(form){
        this.submitted = true;

        if(this.contactForm.invalid) {
            return;
        }

        this.submitted = false;


        const formData = {name : form.name, recipient : form.email, telephone : form.number, msgBody :  form.message, subject : form.subject}
       /* this.sendMailService.sendEmail(formData).then(response => {
            console.log(response);
            // Réinitialiser le formulaire ici si nécessaire
        }, error => {
            console.error(error);
        });*/
    }

    get f() : { [key: string] : AbstractControl} {
        return this.contactForm.controls;
    }

}