import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-acessoires',
  templateUrl: './acessoires.component.html',
  styleUrls: ['./acessoires.component.scss']
})
export class AcessoiresComponent {

  param : string;

  constructor(private route : ActivatedRoute) {
    //this.id = this.route.snapshot.paramMap.get("id");
    this.route.params.subscribe(params => {
      this.param = params['id'];
    });
  }

  ngOnInit(): void {

  }

}
