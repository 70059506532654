import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-solutions-page',
    templateUrl: './solutions-page.component.html',
    styleUrls: ['./solutions-page.component.scss']
})
export class SolutionsPageComponent implements OnInit {

    caseStudiesGrid: number = 1;

    constructor( private titleService: Title ) {
        this.titleService.setTitle('ha2m technology - Solutions');
    }

    ngOnInit(): void {
    }

}