<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Références</h1>
        </div>
    </div>
</div>

<!-- Start Partner Area -->
<app-partner></app-partner>
<!-- End Partner Area -->