import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-baslie-elock',
  templateUrl: './baslie-elock.component.html',
  styleUrls: ['./baslie-elock.component.scss']
})
export class BaslieELockComponent implements OnInit {

  constructor(private router : Router) {
  }
  ngOnInit(): void {
  }

}
