import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { ClientsPageComponent } from './components/pages/clients-page/clients-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { ServicesPageComponent } from './components/pages/services-page/services-page.component';
import { SolutionsPageComponent } from './components/pages/solutions-page/solutions-page.component';
import { TermsConditionsPageComponent } from './components/pages/terms-conditions-page/terms-conditions-page.component';
import {ReferencesComponent} from "./components/pages/references/references.component";
import {TrackingGeolocDetailsPageComponent} from "./components/pages/doamaine-expertise/tracking-geoloc-details-page/tracking-geoloc-details-page.component";
import {CrashDetectionComponent} from "./components/pages/doamaine-expertise/crash-detection/crash-detection.component";
import {CameraIntelligentesComponent} from "./components/pages/solutions-details-page/camera-intelligentes/camera-intelligentes.component";
import {Fmb120Component} from "./components/pages/solutions-details-page/fmb120/fmb120.component";
import {HardwarFuelComponent} from "./components/pages/solutions-details-page/hardwar-fuel/hardwar-fuel.component";
import {BaslieELockComponent} from "./components/pages/solutions-details-page/baslie-elock/baslie-elock.component";
import {PunchLockComponent} from "./components/pages/solutions-details-page/punch-lock/punch-lock.component";
import {GestionCarburantComponent} from "./components/pages/doamaine-expertise/gestion-carburant/gestion-carburant.component";
import {MaintenanceComponent} from "./components/pages/doamaine-expertise/maintenance/maintenance.component";
import {EcoDriveComponent} from "./components/pages/doamaine-expertise/eco-drive/eco-drive.component";
import {OthersServicesComponent} from "./components/pages/doamaine-expertise/others-services/others-services.component";
import {PrivacyPolicyPageComponent} from "./components/pages/privacy-policy-page/privacy-policy-page.component";
import {HomeComponent} from "./components/pages/home/home.component";
import {BalisesBasiquesComponent} from "./components/pages/solutions-details-page/balises-basiques/balises-basiques.component";
import {BalisesAutonomesComponent} from "./components/pages/solutions-details-page/balises-autonomes/balises-autonomes.component";
import {BalisesAvanceesComponent} from "./components/pages/solutions-details-page/balises-avancees/balises-avancees.component";
import {BalisesMultiFonctionsComponent} from "./components/pages/solutions-details-page/balises-multi-fonctions/balises-multi-fonctions.component";
import {BalisesEtanchesComponent} from "./components/pages/solutions-details-page/balises-etanches/balises-etanches.component";
import {BalisesSpecialesComponent} from "./components/pages/solutions-details-page/balises-speciales/balises-speciales.component";
import {PersonalTrackerComponent} from "./components/pages/solutions-details-page/personal-tracker/personal-tracker.component";
import {ObdComponent} from "./components/pages/solutions-details-page/obd/obd.component";
import {AcessoiresComponent} from "./components/pages/solutions-details-page/acessoires/acessoires.component";

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'about', component: AboutPageComponent},
    {path: 'references', component: ReferencesComponent},
    {path: 'contact', component: ContactPageComponent},
    {
        path: 'domaine-expertise',
        children : [
            {path : '',component : ServicesPageComponent },
            {path: 'tacking-geolocalisation', component: TrackingGeolocDetailsPageComponent},
            {path: 'crash-detection', component: CrashDetectionComponent},
            {path: 'gestion-carburant', component: GestionCarburantComponent},
            {path: 'maintenance', component: MaintenanceComponent},
            {path: 'eco-drive', component: EcoDriveComponent},
            {path: 'others-services', component: OthersServicesComponent}
        ]
    },
    {
        path: 'solution',
        children : [
            {path : '', component: SolutionsPageComponent},
            {path: 'balises-basiques/:id', component: BalisesBasiquesComponent},
            {path: 'balises-autonomes/:id', component: BalisesAutonomesComponent},
            {path: 'balises-avancees/:id', component: BalisesAvanceesComponent},
            {path: 'balises-multi-fonctions/:id', component: BalisesMultiFonctionsComponent},
            {path: 'balises-etanches/:id', component: BalisesEtanchesComponent},
            {path: 'balises-speciales/:id', component: BalisesSpecialesComponent},
            {path: 'personal-tracker/:id', component: PersonalTrackerComponent},
            {path: 'obd/:id', component: ObdComponent},
            {path: 'accessoires/:id', component: AcessoiresComponent},
            {path: 'camera-intelligente', component: CameraIntelligentesComponent},
            {path: 'fmb120', component: Fmb120Component},
            {path: 'hardware-fuel', component: HardwarFuelComponent},
            {path: 'balise-e-lock', component: BaslieELockComponent},
            {path: 'punch-lock', component: PunchLockComponent},
        ]
    },

   {path: 'clients', component: ClientsPageComponent},
   {path: 'faq', component: FaqPageComponent},
   {path: 'gallery', component: GalleryPageComponent},
   {path: 'privacy-policy', component: PrivacyPolicyPageComponent},
   {path: 'terms-conditions', component: TermsConditionsPageComponent},

   {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }