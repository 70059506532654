import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-balises-multi-fonctions',
  templateUrl: './balises-multi-fonctions.component.html',
  styleUrls: ['./balises-multi-fonctions.component.scss']
})
export class BalisesMultiFonctionsComponent {

  param : string;

  constructor(private route : ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.param = params['id'];
    });
  }

  ngOnInit(): void {

  }
}
