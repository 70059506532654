import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CountUpModule } from 'ngx-countup';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopHeaderComponent } from './components/common/top-header/top-header.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { LetsTalkComponent } from './components/common/lets-talk/lets-talk.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { ServicesPageComponent } from './components/pages/services-page/services-page.component';
import { ServicesComponent } from './components/common/services/services.component';
import { TestimonialsComponent } from './components/common/testimonials/testimonials.component';
import { TeamStyleOneComponent } from './components/common/team-style-one/team-style-one.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { FeaturedServicesComponent } from './components/common/featured-services/featured-services.component';
import { MainServicesComponent } from './components/common/main-services/main-services.component';
import { WhyChooseUsComponent } from './components/common/why-choose-us/why-choose-us.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { TeamStyleTwoComponent } from './components/common/team-style-two/team-style-two.component';
import { TermsConditionsPageComponent } from './components/pages/terms-conditions-page/terms-conditions-page.component';
import { PrivacyPolicyPageComponent } from './components/pages/privacy-policy-page/privacy-policy-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { ClientsPageComponent } from './components/pages/clients-page/clients-page.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { SolutionsPageComponent } from './components/pages/solutions-page/solutions-page.component';
import { ReferencesComponent } from './components/pages/references/references.component';
import { SchemaExpertiseComponent } from './components/pages/schema-expertise/schema-expertise.component';
import {LocationStrategy, PathLocationStrategy} from "@angular/common";
import {TrackingGeolocDetailsPageComponent} from "./components/pages/doamaine-expertise/tracking-geoloc-details-page/tracking-geoloc-details-page.component";
import { TabDomaineExpertiseComponent } from './components/common/tab-domaine-expertise/tab-domaine-expertise.component';
import { CrashDetectionComponent } from './components/pages/doamaine-expertise/crash-detection/crash-detection.component';
import { GestionCarburantComponent } from './components/pages/doamaine-expertise/gestion-carburant/gestion-carburant.component';
import { EcoDriveComponent } from './components/pages/doamaine-expertise/eco-drive/eco-drive.component';
import { MaintenanceComponent } from './components/pages/doamaine-expertise/maintenance/maintenance.component';
import { OthersServicesComponent } from './components/pages/doamaine-expertise/others-services/others-services.component';
import { CameraIntelligentesComponent } from './components/pages/solutions-details-page/camera-intelligentes/camera-intelligentes.component';
import { Fmb120Component } from './components/pages/solutions-details-page/fmb120/fmb120.component';
import { HardwarFuelComponent } from './components/pages/solutions-details-page/hardwar-fuel/hardwar-fuel.component';
import { BaslieELockComponent } from './components/pages/solutions-details-page/baslie-elock/baslie-elock.component';
import { PunchLockComponent } from './components/pages/solutions-details-page/punch-lock/punch-lock.component';
import {SendMailService} from "./core/services/send-mail.service";
import {HttpClientModule} from "@angular/common/http";
import {HomeComponent} from "./components/pages/home/home.component";
import {HomeFeaturesComponent} from "./components/pages/home/home-features/home-features.component";
import {HomeBannerComponent} from "./components/pages/home/home-banner/home-banner.component";
import { BalisesBasiquesComponent } from './components/pages/solutions-details-page/balises-basiques/balises-basiques.component';
import { BalisesAutonomesComponent } from './components/pages/solutions-details-page/balises-autonomes/balises-autonomes.component';
import { BalisesAvanceesComponent } from './components/pages/solutions-details-page/balises-avancees/balises-avancees.component';
import { BalisesMultiFonctionsComponent } from './components/pages/solutions-details-page/balises-multi-fonctions/balises-multi-fonctions.component';
import { BalisesEtanchesComponent } from './components/pages/solutions-details-page/balises-etanches/balises-etanches.component';
import { BalisesSpecialesComponent } from './components/pages/solutions-details-page/balises-speciales/balises-speciales.component';
import { PersonalTrackerComponent } from './components/pages/solutions-details-page/personal-tracker/personal-tracker.component';
import { ObdComponent } from './components/pages/solutions-details-page/obd/obd.component';
import { OtherSolutionsComponent } from './components/common/other-solutions/other-solutions.component';
import { AcessoiresComponent } from './components/pages/solutions-details-page/acessoires/acessoires.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        TopHeaderComponent,
        NavbarComponent,
        FooterComponent,
        HomeBannerComponent,
        HomeFeaturesComponent,
        FunfactsComponent,
        LetsTalkComponent,
        NotFoundComponent,
        ServicesPageComponent,
        ServicesComponent,
        TestimonialsComponent,
        TeamStyleOneComponent,
        PartnerComponent,
        FeaturedServicesComponent,
        MainServicesComponent,
        WhyChooseUsComponent,
        SubscribeComponent,
        TermsConditionsPageComponent,
        PrivacyPolicyPageComponent,
        ContactPageComponent,
        GalleryPageComponent,
        FaqPageComponent,
        ClientsPageComponent,
        AboutPageComponent,
        SolutionsPageComponent,
        ReferencesComponent,
        SchemaExpertiseComponent,
        TrackingGeolocDetailsPageComponent,
        TabDomaineExpertiseComponent,
        CrashDetectionComponent,
        GestionCarburantComponent,
        EcoDriveComponent,
        MaintenanceComponent,
        OthersServicesComponent,
        CameraIntelligentesComponent,
        Fmb120Component,
        HardwarFuelComponent,
        BaslieELockComponent,
        PunchLockComponent,
        TeamStyleTwoComponent,
        BalisesBasiquesComponent,
        BalisesAutonomesComponent,
        BalisesAvanceesComponent,
        BalisesMultiFonctionsComponent,
        BalisesEtanchesComponent,
        BalisesSpecialesComponent,
        PersonalTrackerComponent,
        ObdComponent,
        OtherSolutionsComponent,
        AcessoiresComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CountUpModule,
        NgxScrollTopModule,
        BrowserAnimationsModule,
        CarouselModule,
        NgxPaginationModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule
    ],
    providers: [SendMailService, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    bootstrap: [AppComponent]
})
export class AppModule { }