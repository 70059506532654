import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-balises-autonomes',
  templateUrl: './balises-autonomes.component.html',
  styleUrls: ['./balises-autonomes.component.scss']
})
export class BalisesAutonomesComponent {

  param : string;

  constructor(private route : ActivatedRoute) {
    //this.id = this.route.snapshot.paramMap.get("id");
    this.route.params.subscribe(params => {
      this.param = params['id'];
    });
  }

  ngOnInit(): void {

  }
}
