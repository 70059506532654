<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Accessoires</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">

        <div class="ptb-10">

            <!------------------------Debut ID-CHAUFFEUR------------------------------->
            <div *ngIf="param==='ID-CHAUFFEUR'">

                <div class="section-title">
                    <h1>Identification chauffeur</h1>
                </div>

                <p>
                    Nos accessoires d’identification chauffeur permettent au gestionnaire de
                    flotte d’avoir une visibilité complète sur les conducteurs et de suivre
                    l’utilisation des véhicules par ces différents chauffeurs.
                </p>

                <p> L’identification de vos chauffeurs vous permet de :</p>
                <ul>
                    <li>Suivre et savoir facilement qui est au volant de quel véhicule.</li>
                    <li>Relever des rapports de conduite propres à chaque chauffeur.</li>
                    <li>Connaître les chauffeurs responsables d’infractions, de violations du
                    code de la route, de vols,....</li>
                </ul>

                <h2>Clé d’identification Dallas</h2>

                <p>
                    La clé d’identification Dallas permet l’identification du salarié et
                    l’autorisation de démarrer le véhicule. Elle assure un taux de sécurité et de
                    fiabilité.
                </p>

                <div>
                    <img src="assets/img/solutions/accessoires/CLE_ID_DALLAS.png" alt="image" >
                </div>

                <h2>Lecteur de badge RFID et MiFare</h2>

                <p>
                    Chaque conducteur possède un badge personnel qui lui permet de
                    s’identifier avant de démarrer le véhicule.
                </p>

                <div>
                    <img src="assets/img/solutions/accessoires/RFID_MiFare.png" alt="image" >
                </div>


            </div>
            <!------------------------ID-CHAUFFEUR FMT100 – 2G------------------------------->




            <!------------------------Debut CAN-BUS ------------------------------->
            <div *ngIf="param==='CAN-BUS'">

                <div class="section-title">
                    <h1>CAN-bus</h1>
                </div>

                <p>
                    Les accessoires Can permettent de relever des informations de l’ordinateur
                    de bord de votre véhicule tels que les kilométrages, le niveau de carburant,
                    la consommation,.... Ces données peuvent être utilisées pour réduire vos
                    coûts d’exploitation, améliorer la sécurité des conducteurs, rationaliser les
                    processus de maintenance et soutenir la responsabilité environnementale.
                </p>

                <ul>
                    <li>
                        Le <strong>LV-CAN200</strong> permet de lire les données du CAN-bus des voitures
                        « classiques », des véhicules légers, des camions, des autobus et des
                        autocars. Il permet d’obtenir facilement les paramètres de base de
                        votre véhicule (kilométrage, niveau de carburant,…). Avec le LV-
                        CAN200+DTC, vous serez en mesure de lire les codes de diagnostic de
                        l’ECU du véhicule et de réagir rapidement en cas de
                        dysfonctionnement.
                    </li>

                    <img src="assets/img/solutions/accessoires/LV-CAN200.png" alt="image" >

                    <li>
                        Le <strong>ALL-CAN300</strong> permet de lire les données de camions, de véhicules
                        légers, d’autobus et d’autocars, de transport agricole et de transports
                        spéciaux (services publics, machines de construction,…).
                    </li>

                    <img src="assets/img/solutions/accessoires/LV-CAN200.png" alt="image" >
                    <li>
                        Le <strong>ECAN01</strong> est un adaptateur sans contact utilisé pour lire les
                        données du CAN-bus du véhicule. Il doit être utilisé en combinaison
                        avec le LV-CAN200, le ALL-CAN300 ou les FMB140 et FMB640 pour
                        lire les données CAN J1939. Il lit les données du CAN-bus en isolant les
                        fils sans les endommager et transmet les signaux au dispositif de suivi.
                    </li>
                </ul>

            </div>
            <!------------------------CAN-BUS ------------------------------->




            <!------------------------Debut BUZZER-EXCES-VITESSE------------------------------->
            <div *ngIf="param==='BUZZER-EXCES-VITESSE'">

                <div class="section-title">
                    <h1>Buzzer excès de vitesse</h1>
                </div>


                <p>
                    Nos buzzers voitures et camions permettent d’alerter les chauffeurs dans la
                    cabine en cas d’excès de vitesses. Ceux-ci peuvent être configurés en
                    fonction des POI (point of interest ou zones géographiques.
                </p>

                <div>
                    <img src="assets/img/solutions/accessoires/BUZZER-EXCES-VITESSE.png" alt="image" >
                </div>

            </div>
            <!------------------------- FIN BUZZER-EXCES-VITESSE–------------------------------->

            <!------------------------Debut BOUTON-PANIC------------------------------->
            <div *ngIf="param==='BOUTON-PANIC'">

                <div class="section-title">
                    <h1>Bouton panic</h1>
                </div>


                <p>
                    Installé au niveau du tableau de bord, à côté du chauffeur, notre gamme de
                    boutons panic permettent aux chauffeurs d’alerter en temps réel leur
                    responsable sécurité en cas de problèmes ou de danger.
                </p>

                <div>
                    <img src="assets/img/solutions/accessoires/BOUTON_PANIC.png" alt="image" >
                </div>

            </div>
            <!------------------------- FIN BOUTON-PANIC–------------------------------->


        </div>






    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h1>Les autres solutions</h1>
        </div>
        <div class="row justify-content-center">
            <app-other-solutions [param]="param"></app-other-solutions>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->