import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-balises-basiques',
  templateUrl: './balises-basiques.component.html',
  styleUrls: ['./balises-basiques.component.scss']
})
export class BalisesBasiquesComponent {

  id : string;
  param : string;

  constructor(private route : ActivatedRoute) {
    this.route.params.subscribe(params => {
       this.id = params['id'];
      this.param = this.route.snapshot.url.join('/');
    });
  }

  ngOnInit(): void {

  }

}
