<!-- Start Footer Area -->
<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo d-inline-block">
                        <img src="../../../../assets/img/others/logo.jpeg" alt="image" style="width: 75%">
                    </a>

                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/people/HA2M-Technology/100091055071079/" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="https://twitter.com" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="https://www.linkedin.com" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pl-4">
                    <h3>Nos solutions</h3>

                    <ul class="links-list">
                        <li><a routerLink="/solution/fmb120">FMB120</a></li>
                        <li><a routerLink="/solution/hardware-fuel">Hardware Fuel</a></li>
                        <li><a routerLink="/solution/balise-e-lock">Balise E-LOCK</a></li>
                        <li><a routerLink="/solution/camera-intelligente">Caméras intelligentes</a></li>
                        <li><a routerLink="/solution/punch-lock">Punchclock (pointeuse mobile)</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Nos domaines d'expertise</h3>

                    <ul class="links-list">
                        <li><a routerLink="/domaine-expertise/tacking-geolocalisation">Tracking et géolocalisation de vos véhicules</a></li>
                        <li><a routerLink="/domaine-expertise/crash-detection">Détection/Analyses de crash</a></li>
                        <li><a routerLink="/domaine-expertise/gestion-carburant" >Gestion de carburant</a></li>
                        <li><a routerLink="/domaine-expertise/eco-drive">ÉCO-DRIVE</a></li>
                        <li><a routerLink="/domaine-expertise/maintenance">Maintenance</a></li>
                        <li><a routerLink="/domaine-expertise/others-services">Autres services</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Informations de contact</h3>

                    <ul class="footer-contact-info">
                        <li>Address: Sis à Ratoma Centre en face de l'école Tom Pouce
                           <br> Commune de Ratoma, Conakry, République de Guinée.</li>
                        <li>Email: <a href="mailto:contact@ha2mtechnology.com">contact@ha2mtechnology.com</a></li>
                        <li>Phone: <a href="tel:+224623834552">+224 623834552</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <!--<p>©2024 TOUS DROITS RÉSERVÉS HA2MTECHNOLOGY</p>-->
                    <p>©2024, Developpé par M&A Enterprise</p>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul>
                        <!--<li><a routerLink="privacy-policy">Privacy Policy</a></li>-->
                        <li><a routerLink="terms-conditions">Version : {{version}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->