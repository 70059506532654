<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Balise E-LOCK</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">
        <div class="page-title-content ptb-50">
            <h2>Balise E-LOCK</h2>
        </div>

        <div class="row ">

            <div class="col-lg-3 col-md-12">
                <img src="assets/img/solutions/balise-e-lock.png" alt="image" >
            </div>

            <div class="col-lg-5  col-md-12">
                <div class="services-details-content">
                    <!--<h2>Module de tracking et de géolocalisation</h2>-->
                    <b>Scellée</b> : la balise prévoit des scellées spécifiques par type de transport
                    <ul>
                        <li>
                            Conteneur : câble acier longueur entre 60 et 100 centimètres
                        </li>
                        <li>
                            Camion bâché : câble acier longueur entre 25 et 35 mètres
                        </li>

                        <li>
                            Camion-citerne : câble de longueur variable jetable
                        </li>

                    </ul>

                    <b>Alertes en temps réel</b> envoyées par mail/sms/pop-up
                    <ul>
                        <li>Détachement de la balise</li>
                        <li>Batterie faible</li>
                        <li>Ouverture scellée</li>
                        <li>Fermeture scellée</li>
                        <li>Non-respect d’itinéraires</li>
                        <li>Etc</li>
                    </ul>

                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <img src="assets/img/solutions/balise-e-lock2.png" alt="image" >
            </div>

        </div>


        <p class="mt-5">Solution pour la protection des marchandises pendant le transport ( douanes, mines, transports agricoles,...)</p>



    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Les autres solutions</h2>
        </div>
        <div class="row justify-content-center">
            <app-featured-services></app-featured-services>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->