import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-camera-intelligentes',
  templateUrl: './camera-intelligentes.component.html',
  styleUrls: ['./camera-intelligentes.component.scss']
})
export class CameraIntelligentesComponent implements OnInit{

  constructor() {
  }

  ngOnInit(): void {
  }

}
