<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Balises spéciales</h1>
        </div>
    </div>
</div>

<div class="services-area ptb-50">

    <div class="container">

        <div class="ptb-10">

            <!------------------------Debut E-Lock – 2G------------------------------->
            <div *ngIf="param==='E-Lock-2G'">

                <div class="section-title">
                    <h1>E-Lock – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La balise E-lock est une balise GPS autonome spéciale (non connectée à la
                    batterie du camion) qui permet de suivre et protéger le transport de
                    marchandises en temps réel. Elle aide les entreprises à <strong>se prémunir contre
                    les risques de vols ou de trafics de marchandises.</strong>
                </p>
                <p>
                    Elle permet, grâce à des <strong>scellés spécifiques</strong> variés et un <strong>système de
                    badging RFID</strong>, une parfaite traçabilité et la détection à distance de toute
                    anomalie laissant augurer d’une fraude ou d’une tentative de
                    détournement.
                </p>

                <p>
                    La balise E-Lock dispose d’un lecteur RFID pour identifier les différents
                    intervenants logistiques (chauffeur, logisticien, douanier,…) et les
                    documents de transport donnant, en temps réel, un statut de la
                    marchandise (où elle est, qui en a la responsabilité,…).
                </p>

                <p>
                    Des alertes configurables envoyés par mails, sms ou notifications sur
                    l’interface garantissent une parfaite réactivité en cas d’action délictueuse.
                    En cas d’arrachement de la balise, de rupture de scellés, de non-respect de
                    l’itinéraire, ou simplement de batterie faible, vous êtes immédiatement
                    averti et vos chargements mieux protégés.
                </p>

                <h2 class="mt-5">Scénarios d’utilisation :</h2>
                <ul class="ml-5">
                    <li>Suivi et protection des transports.</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/E-Lock_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">

                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td> 165 x 85 x 55 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>550g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-20°C à +70°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP67</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------E-LockE-Lock – 2G------------------------------->




            <!------------------------Debut Punchclock – 2G------------------------------->
            <div *ngIf="param==='PUNCHCLOCK–2G'">

                <div class="section-title">
                    <h1>Punchclock – 2G</h1>
                </div>

                <h2>Description :</h2>
                <p>
                    La Punchclock est un appareil multifonctionnel destiné aux entreprises qui
                    souhaitent améliorer le <strong>suivi des temps de leurs employés sur le terrain</strong>,
                    en sécurisant l'ensemble du chantier. Ce dispositif autonome est
                    totalement étanche à l'eau et à la poussière, comprend un GPS pour la
                </p>
                <p>
                    géolocalisation et ne nécessite pas de source d'alimentation permanente.
                    Grâce au réseau mobile GPRS, il suit et communique, en temps réel, toutes
                    les <strong>données relatives aux heures d'arrivée et de départ du personnel de
                    terrain</strong>. L'horodateur mobile supporte à la fois les clés Dallas et les badges
                    RFID Mifare. L'appareil est également équipé d'un GPS et d'un détecteur de
                    mouvement et peut être configuré pour déclencher une alerte lorsque
                    l'appareil est déplacé.
                </p>

                <p>
                    La pointeuse mobile de Viasat Connect est la solution la plus complète et la
                    plus rentable pour gérer et contrôler l'ensemble du projet et du chantier.
                </p>


                <h2 class="mt-5">Scénarios d’utilisation :</h2>

                <ul>
                    <li>pointage et suivi des temps de travail sur chantier.</li>
                </ul>

                <div>
                    <img src="assets/img/solutions/hardware/Punchclock_2G.png" alt="image" >
                </div>

                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">
                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>2G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>165 x 85 x 55 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>550g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>-20°C à +70°C</td>
                    </tr>
                    <tr>
                        <td>Indice de protection</td>
                        <td>IP67</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------FIN Punchclock – 2G------------------------------->



            <div *ngIf="param==='CAMERA-INTELLIGENTE–4G'">

                <div class="section-title">
                    <h1>Caméra intelligente – 4G</h1>
                </div>

                <div>
                    <figure>
                        <img src="assets/img/solutions/hardware/CAMERA_INTELLIGENTE_4G.png" alt="image" >
                        <figcaption class="text-center">Caméra bidirectionnelle</figcaption>
                    </figure>
                </div>

                <ul class="title-li mt-5">
                    <li>CAMÉRA DE ROUTE</li>
                    <p>
                        Vidéo Full HD 1080P avec objectif grand angle réglable à 140° pour
                        capturer tous les détails sur la route.
                    </p>
                    <li>CAMÉRA EN CABINE</li>
                    <p>
                        Caméras couleur et infrarouge grand angle 720P full HD ajustables pour
                        une meilleure reconnaissance faciale la nuit.
                    </p>

                    <li>INVIOLABLE</li>
                    <p>
                        La carte SIM et la fente pour la carte SD de 128 Go sont fixées par des vis
                        de sécurité pour assurer la sécurité de vos images et de votre appareil.
                    </p>

                    <li>CONFIGURATION SIMPLE</li>
                    <p>
                        Le support de montage et la bague rotative qui aident à positionner et à
                        verrouiller la caméra permettent une installation rapide et simple.
                    </p>

                    <li>ENREGISTREMENT MANUEL</li>
                    <p>
                        Le bouton de déclenchement d'événement permet aux conducteurs
                        d'envoyer des notifications par courrier électronique sur les événements
                        de conduite et de télécharger automatiquement des clips vidéo sur le
                        cloud.
                    </p>

                    <li>CONCEPTION TOUT TEMPS</li>
                    <p>
                        Développé pour résister aux saisons, y compris la chaleur et le froid.
                    </p>

                    <li>ALIMENTÉ PAR L'AI ET LES CAPTEURS</li>
                    <p>
                        Détection automatique de la distraction au volant et des conditions de
                    </p>

                    <li>ALERTES AUDIO</li>
                    <p>
                        Fournir un retour d'information audio et des avertissements pour
                        corriger de manière proactive la conduite à risque lorsqu'elle se produit.
                    </p>

                    <li>UNE CONNECTIVITÉ ACCRUE</li>
                    <p>
                        La connectivité 3G/4G et un hotspot WiFi intégré prennent en charge la
                        connexion des appareils IoT et des caméras supplémentaires.
                    </p>

                    <li>ENREGISTREMENT VIDÉO CONTINU</li>
                    <p>
                        Téléchargement automatique de la vidéo sur le cloud via le réseau 3G/4G
                        et enregistrement de la vidéo en continu sur la carte SD de la dashcam.
                    </p>

                    <li>MODE DE STATIONNEMENT</li>
                    <p>
                        Notre batterie intégrée allume automatiquement la caméra de bord dès
                        qu'un risque ou une collision est détecté, et ce pendant une
                        durée maximale de 8 heures.
                    </p>
                </ul>


                <h2 class="mt-5">Caractéristiques techniques :</h2>


                <table class="table table-bordered">
                    <tbody>
                    <tr>
                        <td>Technologie</td>
                        <td>4G</td>
                    </tr>
                    <tr>
                        <td>Dimensions</td>
                        <td>115 x 60 x 50 mm (L x l x H)</td>
                    </tr>
                    <tr>
                        <td>Poids</td>
                        <td>150g</td>
                    </tr>
                    <tr>
                        <td>Température de fonctionnement (sans batterie)</td>
                        <td>10°C à +85°C</td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <!------------------------FIN CAMERA-INTELLIGENTE–4G------------------------------->




        </div>






    </div>
</div>

<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h1>Les autres solutions</h1>
        </div>
        <div class="row justify-content-center">
            <app-other-solutions [param]="param"></app-other-solutions>
        </div>
    </div>
</div>

<app-partner></app-partner>

<!-- End Page Title Area -->
