import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-crash-detection',
  templateUrl: './crash-detection.component.html',
  styleUrls: ['./crash-detection.component.scss']
})
export class CrashDetectionComponent implements OnInit {

  constructor( private titleService: Title ) {
  }

  ngOnInit(): void {
  }

}
