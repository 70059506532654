<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Nos solutions</h1>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Case Studies Area -->
<div class="case-studies-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <app-other-solutions></app-other-solutions>
        </div>
    </div>
</div>
<!-- End Case Studies Area -->

<app-partner></app-partner>

<!--<app-lets-talk></app-lets-talk>-->