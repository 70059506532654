<!-- Start Subscribe Area -->
<div class="{{subscribeClass}}">
    <div class="container">
        <div class="subscribe-inner-area ptb-70">
            <div class="content">
                <h2>Subscribe To Our Weekly Newsletter</h2>
                <form class="newsletter-form">
                    <input type="email" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                    <button type="submit">SUBSCRIBE NOW</button>
                </form>
                <p>No spam, notifications only about new products, updates.</p>
            </div>
        </div>
    </div>
</div>
<!-- End Subscribe Area -->