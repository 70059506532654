<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>Autres services</h1>
        </div>
    </div>
</div>

<div class="services-details-area ptb-70">

    <div class="container">

        <!--<div class="text-center ptb-50">
            <h2>Autres services</h2>
        </div>-->

        <div class="row ">

            <div class="col-lg-6 col-md-12">

                <div class="services-details-content">

                    <ul class="list-group list-group-flush">

                        <li class="list-group-item">
                            <h4 class="mb-4">Asset Management ( suivi et gestion du parc d’objets itinérants)</h4>
                            <ul>
                                <li>Savoir en temps réel qui a déposé quoi, où et quand</li>
                                <li>Savoir en temps réel où se trouve l’objet</li>
                                <li>Combien de temps l’objet est-il resté sur un chantier</li>
                                <li>Connaitre le taux d’utilisation ou de rotation</li>
                            </ul>
                        </li>
                    </ul>

                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/expertise/asset.PNG" alt="image" style="width: 100%">
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="services-details-content">

                    <ul class="list-group list-group-flush">

                        <li class="list-group-item">
                            <h4 class="mb-4">Cartographie personnalisée ( SIG)</h4>
                            <p class="mb-4">Implémentation de cartes personnalisées sur notre plateforme :</p>
                            <ul>
                                <li>Aménagement du Territoire</li>
                                <li>Gestion du cadastre</li>
                                <li>Réseaux : électricité, eau, internet, antennes</li>
                                <li>Infrastructure bornes d’incendie</li>
                                <li>Exploitations : agricoles, minières, forestières üZones : chantier, entreposage, ports</li>
                            </ul>
                        </li>

                    </ul>

                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/expertise/carto.PNG" alt="image" style="width: 100%">
            </div>

            <div class="col-lg-6 col-md-12">

                <div class="services-details-content">

                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <h4 class="mb-4">Web services</h4>
                            <ul>
                                <li>Implémentation des données collectées par nos boîtiers et accessoires dans votre ou
                                    vos applications backoffice (CRM,ERP,etc...)</li>
                                <li>Développement d’une application spécifique à votre métier</li>
                            </ul>
                        </li>
                    </ul>

                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <img src="assets/img/expertise/web.PNG" alt="image" style="width: 100%">
            </div>


        </div>

    </div>

</div>

<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Les autres services</h2>
        </div>
        <app-tab-domaine-expertise></app-tab-domaine-expertise>
    </div>
</div>

<app-partner></app-partner>
