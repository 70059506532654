<!--<div class="container">
    <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let content of singleServicesItem;">
            <div class="single-services-item" >
                <div class="icon">
                    <i class="{{content.icon}}"></i>
                </div>
                <h3><a routerLink="/{{content.detailsLink}}">{{content.title}}</a></h3>
                <p>{{content.paragraph}}</p>
                <a routerLink="/{{content.detailsLink}}" class="link-btn">{{content.btnText}}<i class='bx bx-chevron-right'></i></a>
                <div class="back-icon">
                    <i class="{{content.icon}}"></i>
                </div>
            </div>
        </div>
    </div>
</div>-->


<div class="features-area pt-1 ">
    <div class="container">
        <div class="justify-content-center">

            <div class="faq-accordion accordion" id="faqAccordion">
                <div class="accordion-item" *ngFor="let key of getObjectKeys(data); let i = index">
                    <div class="accordion-header">

                        <button class="accordion-button" type="button" (click)="toggleCollapse(i)"
                                data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i" aria-expanded="true"
                                [attr.aria-controls]="'collapse' + i">
                            {{key}}
                        </button>

                    </div>
                    <div [id]="'collapse' + i" class="accordion-collapse collapse"  data-bs-parent="#faqAccordion">
                        <div class="accordion-body row">
                            <ng-container *ngFor="let content of data[key]; let j = index">
                                <div class="col-lg-4 col-sm-6 col-md-6 custom-tooltip" *ngIf="!param?.includes(content.detailsLink)"
                                     data-bs-placement="top"
                                      data-bs-toggle="tooltip" [attr.data-bs-title]="content.paragraph">
                                    <div class="features-box bg-white single-box-item" routerLink="/{{content.detailsLink}}">
                                        <div class="icon">
                                            <i class="flaticon-video-conference"></i>
                                        </div>
                                        <h3><a routerLink="/{{content.detailsLink}}">{{content.title}}</a></h3>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>