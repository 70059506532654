import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ImageService} from "../../../core/services/image.service";

@Component({
    selector: 'app-gallery-page',
    templateUrl: './gallery-page.component.html',
    styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {

    pageTitle = [
        {
            pageTitle: `Gallerie`
        }
    ]

    galleryItem: { img: string }[] = [];

    constructor( private titleService: Title, private imageService: ImageService) {
        this.titleService.setTitle('Ha2m Technology - Gallery');
    }

    ngOnInit(): void {
        this.galleryItem = this.imageService.getImages();
        console.log(this.galleryItem);
    }



}