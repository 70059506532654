import { Component } from '@angular/core';
import {Title} from "@angular/platform-browser";
import {Router} from "@angular/router";

@Component({
  selector: 'app-tab-domaine-expertise',
  templateUrl: './tab-domaine-expertise.component.html',
  styleUrls: ['./tab-domaine-expertise.component.scss']
})
export class TabDomaineExpertiseComponent {

  singleServicesBox : any[] = []

  constructor( private titleService: Title, private  router : Router) {
    this.titleService.setTitle('ha2m technologie - domaines expertises');
  }

  ngOnInit(): void {
    this.cardList()
  }

  cardList() {

    if(!this.router.url.includes('tacking-geolocalisation')) {
      this.singleServicesBox.push({
        icon: `flaticon-bank`,
        title: `Tracking et géolocalisation de vos véhicules`,
        paragraph: `Géolocalisation, génération de rapports paramétrables, gestion du temps de travail, programmation d’alertes et alarmes, etc...`,
        detailsLink: `domaine-expertise/tacking-geolocalisation`,
        btnText: `En savoir plus`
      });
    }


    if(!this.router.url.includes('crash-detection')) {
        this.singleServicesBox.push({
              icon: `flaticon-manufacturing`,
              title: `Détection/Analyses de crash`,
              paragraph: `Analyse du comportement de conduite, du temps de conduite, alerte et rapport de dépassement de vitesse, rapport détaillé en cas d’accident, etc..`,
              detailsLink: `domaine-expertise/crash-detection`,
              btnText: `En savoir plus`
        });
    }

    if(!this.router.url.includes('gestion-carburant')) {
        this.singleServicesBox.push({
              icon: `flaticon-oil-1`,
              title: `Gestion de carburant`,
              paragraph: `Gestion & suivi des dépenses de carburant, suivi du niveau de carburant, sensibilisation des chauffeurs sur leur gestion du carburant, générer les rapports détaillés, etc...`,
              detailsLink: `domaine-expertise/gestion-carburant`,
              btnText: `En savoir plus`
        });
    }

    if(!this.router.url.includes('eco-drive')) {
        this.singleServicesBox.push({
              icon: `flaticon-antivirus`,
              title: `ÉCO-DRIVE`,
              paragraph: `Proposer un outil de diagnostic et de mesure relatif à l’empreinte écologique d’un parc de véhicules, sensibiliser les chauffeurs sur leur conduite, etc...`,
              detailsLink: `domaine-expertise/eco-drive`,
              btnText: `En savoir plus`
        });
    }

    if(!this.router.url.includes('maintenance')) {
      this.singleServicesBox.push({
            icon: `flaticon-keywords`,
            title: `Maintenance`,
            paragraph: `Automatiser les alertes de maintenance, réduire le nombre d’accidents, planifier chaque opération d’entretien pour chaque véhicule et garantir le suivi, etc..`,
            detailsLink: `domaine-expertise/maintenance`,
            btnText: `En savoir plus`
      })
    }

    if(!this.router.url.includes('others-services')) {
        this.singleServicesBox.push({
              icon: 'flaticon-protection',
              title: 'Autres services',
              paragraph: "Suivi et gestion du parc d’objets itinérants, implémentation de cartes personnalisées sur notre plateformee et développement d’une application spécifique à votre métier",
              detailsLink: 'domaine-expertise/others-services',
              btnText: 'En savoir plus'
        });
    }

  }

}
