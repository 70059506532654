import {Component, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit {

  constructor( private titleService: Title ) {
    this.titleService.setTitle('Naxai - Awards');
  }

  ngOnInit(): void {
  }
}
