import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            subTitle: `Nos services`,
            title: `We Offer Professional Security Solutions For Business`,
            paragraph: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
        }
    ]
    singleServicesBox = [
        {
            icon: `bi bi-geo-alt-fill`,
            title: `MODULE DE TRACKING ET DE GÉOLOCALISATION`,
            paragraph: `
            <ul>
                <li>Géolocalisation : cartographie mondiale avec vue satellite Google Maps, Google Street & Open Street Map</li>
                <li>Génération de rapports paramétrables (trajets, arrêts, carburant, etc.) avec export en PDF et CSV.</li>
                <li>etc...</li>
            </ul>
            `,
            detailsLink: `/domaine-expertise/tacking-geolocalisation`,
            btnText: `EN SAVOIR PLUS`
        },
        {
            icon: `bi bi-fuel-pump`,
            title: `MODULE DE GESTION DE CARBURANT`,
            paragraph: `
            <ul>
                <li>Gestion & suivi des dépenses de carburant</li>
                <li>Suivi du niveau de carburant</li>
                <li>Sensibilisation des chauffeurs sur leur gestion du carburant</li>
                <li>etc...</li>
            </ul>
            `,
            detailsLink: `/domaine-expertise/gestion-carburant`,
            btnText: `EN SAVOIR PLUS`
        },
        {
            icon: `flaticon-manufacturing`,
            title: `MODULE ÉCO-DRIVE`,
            paragraph: `
            <ul>
                <li>Sécuriser vos chauffeurs</li>
                <li>Sensibiliser les chauffeurs sur leur conduite</li>
                <li>Protéger les véhicules et augmenter leurs temps de vie</li>
                <li>etc...</li>
            </ul>
            `,
            detailsLink: `/domaine-expertise/eco-drive`,
            btnText: `EN SAVOIR PLUS`
        },
        {
            icon: `flaticon-oil-1`,
            title: `MODULE MAINTENANCE`,
            paragraph: `
            <ul>
                <li>Réduire le nombre d’accidents</li>
                <li>Planifier chaque opération d’entretien pour chaque véhicule et garantir le suivi</li>
                <li>Gain de temps et de personnels</li>
                <li>etc...</li>
            </ul>
            `,
            detailsLink: `/domaine-expertise/maintenance`,
            btnText: `EN SAVOIR PLUS`
        },
        {
            icon: `flaticon-antivirus`,
            title: `CRASH DETECTION`,
            paragraph: `
            <span>Les accidents coûtent chers à tous!</span>
            <ul>
                <li>Analyse du comportement de conduite</li>
                <li>Analyse du temps de conduite</li>
                <li>Alerte et rapport de dépassement de vitesse</li>
                <li>etc...</li>
            </ul>
            `,
            detailsLink: `/domaine-expertise/crash-detection`,
            btnText: `EN SAVOIR PLUS`
        },
        {
            icon: `flaticon-keywords`,
            title: `AUTRES SERVICES`,
            paragraph: `
            <ul>
                <li>Asset Management ( suivi et gestion du parc d’objets itinérants)</li>
                <li>Cartographie personnalisée ( SIG)</li>
                <li>Web services(développement d’une application spécifique à votre métier)</li>
            </ul>
            `,
            detailsLink: `/domaine-expertise/others-services`,
            btnText: `EN SAVOIR PLUS`
        }
    ]

    servicesSlides: OwlOptions = {
        loop: true,
		nav: false,
		dots: true,
		margin: 30,
        autoplay: true,
        autoplayTimeout: 4000,
		autoplayHoverPause: true,
		navText: [
			"<i class='bx bx-chevron-left'></i>",
			"<i class='bx bx-chevron-right'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 1
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			}
		}
    }

}