<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h1>ÉCO-DRIVE</h1>
        </div>
    </div>
</div>


<div class="services-details-area ptb-70">

    <div class="container">

        <div class="text-center ptb-50">
            <h2>Module ÉCO-DRIVE</h2>
        </div>

        <div class="row ">

            <div class="col-lg-2 col-md-12">
                <img src="assets/img/expertise/eco-drive.png" alt="image" >
            </div>

            <div class="col-lg-10 col-md-12">

                <div class="services-details-content">
                    <ul>
                        <li>Proposer un outil de diagnostic et de mesure relatif à l’empreinte écologique d’un parc de véhicules</li>
                        <li>Sensibiliser les chauffeurs sur leur conduite</li>
                        <li>Analyser les violations réalisées par les chauffeurs ( excès de vitesses, accidents, freinages et
                        tournants brusques, trous, bosses...)</li>
                        <li>Sécuriser vos chauffeurs</li>
                        <li>Protéger les véhicules et augmenter leurs temps de vie</li>
                        <li>Réduire les coûts de maintenance et la consommation de carburant</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 mt-5">
                <img src="assets/img/expertise/eco-drive1.png" alt="image" style="width: 100%">
            </div>

            <div class="col-lg-6 col-md-12 mt-5">
                <img src="assets/img/expertise/eco-drive2.png" alt="image" style="width: 100%">
            </div>

        </div>

    </div>

</div>


<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Les autres services</h2>
        </div>
        <app-tab-domaine-expertise></app-tab-domaine-expertise>
    </div>
</div>

<app-partner></app-partner>

