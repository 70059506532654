<!-- Start Features Area -->
<div class="features-area mt-minus">
    <div class="container">
        <div class="features-inner">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-6" *ngFor="let content of singleFeaturesBox;">
                    <div class="single-features-box">
                        <div class="icon">
                            <i *ngIf="content.icon" class="{{content.icon}}"></i>
                            <img *ngIf="content.img" src="assets/img/{{content.img}}"  style="width: 15%;">
                        </div>
                        <h3><a routerLink="/{{content.link}}">{{content.title}}</a></h3>
                        <p>{{content.paragraph}}</p>
                        <a routerLink="/{{content.link}}" class="link-btn">{{content.linkText}} <i class='bx bx-chevron-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-why-choose-us></app-why-choose-us>
<!-- End Features Area -->