import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-other-solutions',
  templateUrl: './other-solutions.component.html',
  styleUrls: ['./other-solutions.component.scss']
})
export class OtherSolutionsComponent implements OnChanges {
  singleServicesItem : any[] = [];

  data : any;
  etatsCollapse: boolean[] = [];

  @Input()
  param : string;
  urls : string;
  constructor(private router : Router, private route : ActivatedRoute) {
    /*this.route.url.subscribe(url => {
      this.param = this.route.snapshot.url.join('/');
    })*/
  }

  ngOnInit(): void {
    this.cardList();
    this.etatsCollapse = new Array(this.data.length).fill(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['param']) {
      // Effectuez ici les actions nécessaires lorsque la valeur change.
      this.param = '/solution/' + changes['param'].currentValue;

    }
  }

  toggleCollapse(index: number) {
    this.etatsCollapse[index] = !this.etatsCollapse[index];
  }


  cardList() {
    this.singleServicesItem = [];

    this.data = {
      "Balises basiques" : [
        {
          icon: `bi bi-camera-video`,
          title: `FMT100 – 2G`,
          paragraph: `La balise FMT100 est un petit tracker GNSS intelligent et étanche avec connectivité Bluetooth et batterie de secours interne. `,
          detailsLink: `/solution/balises-basiques/FMT100-2G`,
          btnText: `EN SAVOIR PLUS`
        },
        {
            icon: `bi bi-camera-video`,
            title: `FMB920 – 2G`,
            paragraph: `La balise FMB920 est un tracker compact et intelligent avec connectivité
        Bluetooth, antennes internes GNSS et GSM à gain élevé et batterie de secours intégrée.`,
            detailsLink: `/solution/balises-basiques/FMB920-2G`,
            btnText: `EN SAVOIR PLUS`
        },
        {
          icon: `bi bi-camera-video`,
          title: `FMM920 – 4G`,
          paragraph: `Le FMM920 est un petit tracker intelligent doté de la connectivité Bluetooth,
          d'antennes GNSS et GSM à haut gain internes et d'une batterie de secours intégrée.`,
          detailsLink: `/solution/balises-basiques/FMM920-4G`,
          btnText: `EN SAVOIR PLUS`
        }
      ],
      "Balises autonomes" : [
        {
          icon: 'bi bi-camera-video',
          title: 'Standalone – 2G',
          paragraph: 'La balise Standalone est un boîtier de géolocalisation fonctionnant sur\n' +
              'batterie (ne nécessite pas d’installation ni de connexion au véhicule).',
          detailsLink: '/solution/balises-autonomes/STANDALONE-2G',
          btnText: 'EN SAVOIR PLUS'
        },
        {
          icon: 'bi bi-camera-video',
          title: 'TAT100 – 2G',
          paragraph: 'La balise TAT100 est une toute nouvelle solution de suivi des biens. Son\n' +
              'boîtier robuste, pratique et étanche (IP68), combiné à différents accessoires\n' +
              'de montage, permet une installation extrêmement facile.',
          detailsLink: '/solution/balises-autonomes/TAT100-2G',
          btnText: 'EN SAVOIR PLUS'
        },
        {
          icon: 'bi bi-camera-video',
          title: 'AT4 – 2G',
          paragraph: 'Ses fonctions avancées, sa construction robuste et sa batterie d\'une\n' +
              'autonomie inégalée font de l\'AT4 la nouvelle référence en matière de suivi\n' +
              'GPS de véhicules.',
          detailsLink: '/solution/balises-autonomes/AT4–2G',
          btnText: 'EN SAVOIR PLUS'
        }
      ],
      "Balises avancées" : [
        {
          icon: 'bi bi-camera-video',
          title: 'FMB640 – 2G',
          paragraph: 'La balise FMB640 est un terminal GNSS, GSM pour les applications professionnelles.',
          detailsLink: '/solution/balises-avancees/FMB640-2G',
          btnText: 'EN SAVOIR PLUS'
        },
        {
          icon: 'bi bi-camera-video',
          title: 'FMM640 – 4G CAT M1',
          paragraph: 'La balise FMM640 est un tracker avec connectivité LTE CAT M1, NB IoT, qui rend cet appareil utilisable' +
              ' avec les technologies les plus récentes et les plus rentables.',
          detailsLink: '/solution/balises-avancees/FMM640–4G-CAT-M1',
          btnText: 'EN SAVOIR PLUS'
        }
      ],

      "Balises « multi-fonctions »" : [
        {
          icon: 'bi bi-camera-video',
          title: 'FMB120 – 2G',
          paragraph: 'La balise FMB120 est un petit tracker avec antennes GSM et GNSS internes\n' +
              'à gain élevé, qui est capable de collecter les coordonnées de l’appareil et\n' +
              'd’autres données utiles et de les transférer via le réseau GSM vers le serveur.',
          detailsLink: '/solution/balises-multi-fonctions/FMB120-2G',
          btnText: 'EN SAVOIR PLUS'
        },
        {
          icon: 'bi bi-camera-video',
          title: 'FMM130 – 4G CAT M1',
          paragraph: 'La balise FMM130 est un petit terminal de suivi avec connectivité GNSS et LTE CAT-M1 / NB -IoT / GSM et batterie de secours.',
          detailsLink: '/solution/balises-multi-fonctions/FMM130–4G-CAT-M1',
          btnText: 'EN SAVOIR PLUS'
        }
      ],

      "Balises étanches" : [
        {
          icon: 'bi bi-camera-video',
          title: 'FMB204 – 2G',
          paragraph: 'La balise FMB204 est un tracker étanche (IP67) avec connectivité Bluetooth,\n' +
              'antennes internes GNSS et GSM à gain élevé et batterie de secours haute\n' +
              'capacité intégrée.',
          detailsLink: '/solution/balises-etanches/FMB204-2G',
          btnText: 'EN SAVOIR PLUS'
        }
      ],

      "Balises spéciales" : [
        {
          icon: 'bi bi-camera-video',
          title: 'E-Lock – 2G',
          paragraph: 'La balise E-lock est une balise GPS autonome spéciale (non connectée à la\n' +
              'batterie du camion) qui permet de suivre et protéger le transport de\n' +
              'marchandises en temps réel.',
          detailsLink: '/solution/balises-speciales/E-Lock-2G',
          btnText: 'EN SAVOIR PLUS'
        },
        {
          icon: 'bi bi-camera-video',
          title: 'Punchclock – 2G',
          paragraph: 'La Punchclock est un appareil multifonctionnel destiné aux entreprises qui\n' +
              'souhaitent améliorer le suivi des temps de leurs employés sur le terrain,\n' +
              'en sécurisant l\'ensemble du chantier.',
          detailsLink: '/solution/balises-speciales/PUNCHCLOCK–2G',
          btnText: 'EN SAVOIR PLUS'
        },
        {
          icon: 'bi bi-camera-video',
          title: 'Caméra intelligente 4G',
          paragraph: 'Caméra intelligente',
          detailsLink: '/solution/balises-speciales/CAMERA-INTELLIGENTE–4G',
          btnText: 'EN SAVOIR PLUS'
        }
      ],

      "Personal tracker" : [
        {
          icon: 'bi bi-camera-video',
          title: 'IConnect – 2G',
          paragraph: 'L’IConnect est un tracker personnel autonome avec une connectivité GNSS,\n' +
              'GSM et Bluetooth.',
          detailsLink: '/solution/personal-tracker/ICONNECT-2G',
          btnText: 'EN SAVOIR PLUS'
        },
        {
          icon: 'bi bi-camera-video',
          title: 'TMT250 – 2G',
          paragraph: 'Le TMT250 est un tracker personnel autonome avec une connectivité GNSS,\n' +
              'GSM et Bluetooth.',
          detailsLink: '/solution/personal-tracker/TMT250-2G',
          btnText: 'EN SAVOIR PLUS'
        }
      ],

      "OBD" : [
        {
          icon: 'bi bi-camera-video',
          title: 'FMB003 – 2G',
          paragraph: 'La balise FMB003 est un appareil plug-and-play ultra-petit dédié aux\n' +
              'applications OBD de nouvelle génération.',
          detailsLink: '/solution/obd/FMB003-2G',
          btnText: 'EN SAVOIR PLUS'
        },
        {
          icon: 'bi bi-camera-video',
          title: 'FMM003 – 4G',
          paragraph: 'La balise FMM003 est un dispositif avancé plug and play LTE CAT-M1 dédié\n' +
              'à la prochaine génération d\'applications OBD.',
          detailsLink: '/solution/obd/FMM003-4G-CAT-M1',
          btnText: 'EN SAVOIR PLUS'
        }
      ],

      "Accessoires" : [
        {
          icon: 'bi bi-camera-video',
          title: 'Identification chauffeur',
          paragraph: 'Nos accessoires d’identification chauffeur permettent au gestionnaire de\n' +
              'flotte d’avoir une visibilité complète sur les conducteurs et de suivre\n' +
              'l’utilisation des véhicules par ces différents chauffeurs.',
          detailsLink: '/solution/accessoires/ID-CHAUFFEUR',
          btnText: 'EN SAVOIR PLUS'
        },
        {
          icon: 'bi bi-camera-video',
          title: 'CAN-bus',
          paragraph: 'Les accessoires Can permettent de relever des informations de l’ordinateur\n' +
              'de bord de votre véhicule tels que les kilométrages, le niveau de carburant,\n' +
              'la consommation,....',
          detailsLink: '/solution/accessoires/CAN-BUS',
          btnText: 'EN SAVOIR PLUS'
        },
        {
          icon: 'bi bi-camera-video',
          title: 'Identification chauffeur',
          paragraph: 'Nos buzzers voitures et camions permettent d’alerter les chauffeurs dans la\n' +
              'cabine en cas d’excès de vitesses. Ceux-ci peuvent être configurés en\n' +
              'fonction des POI (point of interest ou zones géographiques.',
          detailsLink: '/solution/accessoires/BUZZER-EXCES-VITESSE',
          btnText: 'EN SAVOIR PLUS'
        },
        {
          icon: 'bi bi-camera-video',
          title: 'Bouton panic',
          paragraph: 'Installé au niveau du tableau de bord, à côté du chauffeur, notre gamme de\n' +
              'boutons panic permettent aux chauffeurs d’alerter en temps réel leur\n' +
              'responsable sécurité en cas de problèmes ou de danger.',
          detailsLink: '/solution/accessoires/BOUTON-PANIC',
          btnText: 'EN SAVOIR PLUS'
        }
      ],

    };

  }

  getObjectKeys(obj: any): string[] {
    if(obj) return Object.keys(obj);
  }

  toggleAccordion(index: number) {
    console.log(index)
    const elementId = 'collapse' + index;
    const element = document.getElementById(elementId);
    console.log(element)
    if (element) {
      console.log(element.classList)
      if (element.classList.value.includes('show')) {
        element.classList.remove('show');
      } else {
        element.classList.replace('show', '');
        element.classList.add('show');
      }
    }
  }
}
