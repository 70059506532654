import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

@Component({
    selector: 'app-lets-talk',
    templateUrl: './lets-talk.component.html',
    styleUrls: ['./lets-talk.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class LetsTalkComponent implements OnInit {

    location: any;
    letsTalkClass: any;

    constructor(
        private router: Router,
        location: Location
    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/index-2'){
                    this.letsTalkClass = 'lets-talk-area bg-f5f5f5 ptb-100 pb-extra';
                } else if (this.location == '/index-3') {
                    this.letsTalkClass = 'lets-talk-area bg-d8eafe ptb-100';
                } else {
                    this.letsTalkClass = 'lets-talk-area ptb-100';
                }
            }
        });
    }

    ngOnInit(): void {
    }

    letsTalkContent = [
        {
            title: `Discutez de vos besoins`,
            paragraph: `
            Nous sommes ravis de pouvoir échanger avec vous. Si vous avez des projets, des questions, 
            des commentaires ou si vous souhaitez simplement discuter, n'hésitez pas à nous contacter. 
            Votre avis est important pour nous et nous sommes là pour vous aider. L’équipe sera ravie de vous répondre.`,
            buttonLink: `contact`,
            buttonText: `Contactez-nous`
        }
    ]

}